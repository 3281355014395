@import '../../common-style//variable.scss';

.edit_order_main {
    .heading_otr {
        margin-bottom: 24px;
    }

    .heading_page {
        color: $Black;
    }

    .edit_form_main {
        margin: 0;
        padding: 0;
    }

    .input_main {
        display: flex;
        align-items: center;
        gap: 24px;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    .input-container {
        width: 50%;
    }

    .form_input {
        &::placeholder {
            color: $Black;
        }
    }

    .action_otr {
        display: flex;
        margin-top: 30px;
    }
}