@import '../../common-style/variable.scss';

.about_row_custom {
    @media (max-width: 575px) {
        flex-direction: column-reverse;
    }

    .col_content_inr {
        padding-right: 48px;

        @media (max-width: 992px) {
            padding-right: 0;
        }

        .about_heading {
            color: $Black;
            margin-bottom: 16px;
        }

        .desc {
            color: $Black-65;

            @media (max-width: 575px) {
                font-size: 16px;
                margin-top: 0 !important;
            }

            &:not(:last-child) {
                margin-bottom: 16px;

                @media (max-width: 575px) {
                    margin-bottom: 12px;
                }
            }
        }
    }

    .col_img_inr {
        position: relative;
        background-color: $Primary-Light;
        border-radius: 16px;
        padding: 24px;

        @media (max-width: 575px) {
            padding: 10px;
        }

        .about_img {
            position: relative;
            width: 100%;
            height: 50%;
            object-fit: cover;
            object-position: top;
            z-index: 2;
            border-radius: 12px;

            @media (max-width: 992px) {
                height: 400px;
            }

            @media (max-width: 575px) {
                height: 350px;
            }
        }

        .about_shape {
            position: absolute;
        }

        .shape1 {
            width: 180px;
            height: 180px;
            object-fit: contain;
            object-position: center;
            top: -45px;
            right: -45px;
            animation-name: rotate;
            animation-duration: 15s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            z-index: -1;
        }

        .shape2 {
            width: 410px;
            height: 410px;
            object-fit: contain;
            object-position: center;
            bottom: -54px;
            right: -90px;
            z-index: -1;
        }
    }
}


@keyframes UpDown {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(360deg);
    }

    to {
        -moz-transform: rotate(0);
    }
}

@-ms-keyframes rotate {
    from {
        -ms-transform: rotate(360deg);
    }

    to {
        -ms-transform: rotate(0);
    }
}

@-o-keyframes rotate {
    from {
        -o-transform: rotate(360deg);
    }

    to {
        -o-transform: rotate(0);
    }
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0);
    }
}