@import './common-style/variable.scss';



.dashboard-header-color {
    // background-color: #FEEAE0;
}

.dashboard-body-color {
    background-color: #FAFAFA;
}


html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Switzer,sans-serif;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    display: block;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
}

.container-fluid {
    padding: 0 45px;

    @media (max-width: 1200px) {
        padding: 0 20px;
    }
}

.container {
    @media (max-width: 1200px) {
        padding: 0 20px;
        width: 100%;
        max-width: 100%;
    }
}


.heading-h1 {
    font-size: $font-size-60px;
    line-height: $line-height-72px;
    font-family: $font-family-Bold;
}

.heading-h2 {
    font-size: $font-size-48px;
    line-height: $line-height-64px;
    font-family: $font-family-Bold;

    @media (max-width: 575px) {
        font-size: 28px;
        line-height: 48px;
    }
}

.heading-h3 {
    font-size: $font-size-24px;
    line-height: $line-height-36px;
    font-family: $font-family-Bold;
}

.heading-h22 {
    font-size: 36px;
    line-height: $line-height-36px;
    font-family: $font-family-Bold;

    @media (max-width: 575px) {
        font-size: 28px;
        line-height: 48px;
    }
}

.heading-lb {
    font-size: $font-size-20px;
    line-height: $line-height-32px;
    font-family: $font-family-SemiBold;
}

.heading-l {
    font-size: $font-size-20px;
    line-height: $line-height-32px;
    font-family: $font-family-Regular;
}

.heading-mb {
    font-size: $font-size-18px;
    line-height: $line-height-30px;
    font-family: $font-family-SemiBold;
}

.heading-m {
    font-size: $font-size-18px;
    line-height: $line-height-30px;
    font-family: $font-family-Regular;
}

.heading-sb {
    font-size: $font-size-16px;
    line-height: $line-height-28px;
    font-family: $font-family-Bold;
}

.heading-sb-semibold {
    font-size: $font-size-16px;
    line-height: $line-height-28px;
    font-family: $font-family-SemiBold;
}

.heading-s {
    font-size: $font-size-16px;
    line-height: $line-height-28px;
    font-family: $font-family-Regular;
}

.heading-xs {
    font-size: $font-size-14px;
    line-height: $line-height-26px;
    font-family: $font-family-Regular;
}

.heading-xsb {
    font-size: $font-size-14px;
    line-height: $line-height-26px;
    font-family: $font-family-SemiBold;
}

.heading-xxs {
    font-size: $font-size-12px;
    line-height: $line-height-20px;
    font-family: $font-family-Regular;
}

.heading-xxsb {
    font-size: $font-size-12px;
    line-height: $line-height-20px;
    font-family: $font-family-SemiBold;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: $White;
}

::-webkit-scrollbar-thumb {
    background: $Primary;
    transition: .3s;
}

::-webkit-scrollbar-thumb:hover {
    background: $Primary-Dark;
}


.dashboard_main {
    .sidebar_content_main {
        display: flex;
    }

    .dashboard_header_main {
        border-bottom: 1px solid $Black-10;
        margin-bottom: 24px;

        @media (max-width: 992px) {
            margin-bottom: 16px;
        }
    }

    .Header_content_main {
        flex: 1;
        width: calc(100% - 280px);
    }

    .content_main {
        margin-left: 24px;
        border-top: 1px solid $Black-10;
        border-left: 1px solid $Black-10;
        width: calc(100% - 24px);
        min-height: calc(100vh - 106px);
        padding: 24px;

        @media (max-width: 992px) {
            margin: 0 16px;
            width: calc(100% - 32px);
            padding: 20px 16px;
            border-right: 1px solid $Black-10;
            height: calc(100vh - 90px);
        }

        @media (max-width: 575px) {
            height: calc(100vh - 72px);
            padding: 16px 16px 20px 16px;
        }
    }
}


.margin-temp {
    margin-bottom: 150px;
    
}


.landing_contact_otr {
    margin-top: 112px;

    @media (max-width: 575px) {
        margin-top: 48px;
    }
}

._loading_overlay_overlay {
    position: FIXED !important;
}

.font25 {
    font-size: 25px !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}
