@import '../../common-style/variable.scss';

.search-button {
    border: none;
    background: none;
}

.padding {
    margin-top: 50px;
}

.card_desc_activity{
    color: black;
}

.searchRsult_page {
    .searchRsult_main {
        padding: 112px 0 128px 0;

        @media (max-width: 575px) {
            padding: 36px 0 48px 0;
        }
    }

    .filter_heading {
        color: $Black;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 1556px;
        font-size: 22px;
        @media (max-width: 420px) {
            font-size: 14px !important;
        }
    }


    .filter_main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 48px 0;
        justify-content: center;
    }

    .activity_main {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .activity_ul {
        display: flex;
        align-items: center;
        gap: 6px;
        background-color: $Black-3;
        padding: 6px 10px 6px 16px;
        border-radius: 8px;

        @media (max-width: 450px) {
            display: grid;
            gap: 0;
            width: 100%;
            grid-template-columns: auto auto;
            padding: 6px 0 12px 6px;
            border-radius: 8px 8px 0 0;
        }

        .activity_li {
            .Theme_select{
                font-size: 19px;
                @media (max-width: 450px) {
                    font-size: 10px;
                }
            }
            &:not(:first-child) {
                .Theme_select {
                    padding-left: 10px;
                }
            }

            &:nth-child(2),
            &:nth-child(4) {
                @media (max-width: 450px) {
                    border-right: none;
                }

                .Theme_select {
                    @media (max-width: 450px) {
                        border-right: none;
                    }
                }
            }

            @media (max-width: 450px) {
                border-bottom: 1px solid $Black-65;

                .Theme_select {
                    padding-left: 10px;
                }
            }
        }

        .Theme_select {
            padding: 2px 36px 2px 0;
            background-color: transparent;
            border-radius: 0;
            background-position: right 0.65rem center;
        }
    }

    .row_custom {
        gap: 24px 0;
    }

    .action_otr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 72px;
    }
}

.categoryInfo{
    margin: 20px auto;
    text-align: center;
}