/*=====================================
        Color Variable's
=====================================*/

$Primary-Dark: #321E14;
$Primary: #FC9765;
$Primary-Light: #FECBB2;
$Primary-Light2: #FEEAE0;
$White: #FFFFFF;
$White-25: rgba(255, 255, 255, 0.25);
$Black: #000000;
$Black-65: rgba(0, 0, 0, 0.65);
$Black-40: rgba(0, 0, 0, 0.40);
$Black-25: rgba(0, 0, 0, 0.25);
$Black-10: rgba(0, 0, 0, 0.10);
$Black-3: rgba(0, 0, 0, 0.03);
$Grey-Bg: #F7F7F7;
$Green: #0AB39C;
$Red: #FF0000;
$Primary-Theme1: #fc9765;
/*=====================================
        Font Size Variable's
=====================================*/
$font-size-60px: 60px;
$font-size-48px: 48px;
$font-size-24px: 24px;
$font-size-20px: 20px;
$font-size-18px: 18px;
$font-size-16px: 16px;
$font-size-14px: 14px;
$font-size-12px: 12px;

/*=====================================
        Line Height Variable's
=====================================*/

$line-height-72px: 72px;
$line-height-64px: 64px;
$line-height-36px: 36px;
$line-height-32px: 32px;
$line-height-30px: 30px;
$line-height-28px: 28px;
$line-height-26px: 26px;
$line-height-24px: 24px;
$line-height-20px: 20px;

/*=====================================
        Box Shadow Variable's
=====================================*/

$Box-Shadow-Custom: 0px 4px 25px rgba(0, 0, 0, 0.1);

/*=====================================
        Font Family Variable's
=====================================*/

$font-family-Bold: "Inter-Bold";
$font-family-SemiBold: "Inter-SemiBold";
$font-family-Medium: "Inter-Medium";
$font-family-Regular: "Inter-Regular";