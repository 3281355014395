@import '../../common-style/variable.scss';


.check_main {
    cursor: pointer;

    .form-check {
        display: flex;
        align-items: center;
        gap: 16px;

        @media (max-width: 575px) {
            gap: 10px;
        }
    }

    .form-check-input {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid $Primary;
        background-color: $White;

        &:focus {
            box-shadow: none;
        }
    }

    .form-check-input:checked[type=checkbox] {
        background-image: url(../../img/check-icon.svg);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .form-check-label {
        color: $Black-65;
    }
}