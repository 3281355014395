@import "../../common-style/variable.scss";

.change-partner {
  display: flex;
  gap: 50px;
}
.editButton {
  margin-bottom: 10px;
}

.error_message {
  border: 1px solid red;
  color: red;
  border-radius: 4px;
  margin: 20px 280px;
  font-weight: bold;
  text-align: center;
}

.success_message {
  border: 1px solid green;
  color: green;
  border-radius: 4px;
  margin: 20px 280px;
  font-weight: bold;
  text-align: center;
}

//descrition textbox
//todo: remove padding in the textbox
.description {
  height: 231px;
  text-align: start;
  padding: 26px 26px 52px 26px;
  line-height: 24px !important;
}

// .image-uploader {
//   width: 600px;
//   height: 500px;
//   margin-top: 20px;

// }

.slider-container {
  display: flex;
}

.error_message {
  border: 1px solid red;
  color: red;
  border-radius: 4px;
  margin: 20px 80px;
  padding: 25px 40px;
  font-weight: bold;
}

.success_message {
  border: 1px solid green;
  font-weight: bold;
  color: green;
  border-radius: 4px;

  margin: 20px 80px;
  padding: 25px 40px;
}

.Register_page {
  .Register_main {
    padding: 112px 0 128px 0;

    @media (max-width: 575px) {
      padding: 72px 0;
    }
  }

  .row_custom {
    justify-content: center;
  }

  .col_form_inr {
    background-color: $Primary-Light2;
    padding: 24px 30px 30px 30px;
    border-radius: 16px;

    @media (max-width: 575px) {
      padding: 16px;
      border-radius: 8px;
    }
  }

  .form_heading {
    color: $Black;
    margin-bottom: 24px;
    text-align: center;

    @media (max-width: 575px) {
      margin-bottom: 16px;
    }
  }

  .form_main {
    margin: 0;
  }

  .Input_otr {
    margin-bottom: 24px;

    @media (max-width: 575px) {
      margin-bottom: 16px;
    }
  }

  .action_otr {
    display: flex;
    margin: 36px 0 24px 0;

    @media (max-width: 575px) {
      margin: 24px 0 16px 0;
    }
  }

  .form_btn {
    width: 100%;
  }

  .login_text_otr {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login_text {
    color: $Black;
    transition: 0.3s;

    &:hover {
      color: $Primary;
    }
  }
}

.col_img_inr {
  .campaign_swiper {
    background-color: $White;
    box-shadow: $Box-Shadow-Custom;
    padding: 12px;
    border-radius: 16px;

    @media (max-width: 575px) {
      padding: 16px;
    }
  }
  .campaign_img {
    width: 100%;
    height: 400px;
    object-fit:cover;
    object-position: center;
    // animation-name: UpDown;
    // animation-duration: 6s;
    // animation-iteration-count: infinite;
    // animation-timing-function: linear;
}

  .social_text_otr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $Primary-Light2;
    border-radius: 8px;
    padding: 28px 36px;
    margin-top: 72px;
  }

  .text_ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .text_heading {
    display: flex;
    align-items: center;
    color: $Black;

    .icon_text_otr {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-right: 16px;
    }

    .q_icon {
      font-size: 24px;
      line-height: 24px;
      color: $Primary;
      transition: 0.3s;
    }
  }

  .social_ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 120px;
    gap: 16px;
  }

  .social_a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    background-color: $White;
    box-shadow: $Box-Shadow-Custom;
    transition: 0.3s;

    &:hover {
      background-color: $Primary;

      .social_icon {
        color: $White;
      }
    }

    .social_icon {
      font-size: 24px;
      line-height: 24px;
      color: $Primary;
      transition: 0.3s;
    }
  }
}

.ql-container {
    height: 80% !important;
}