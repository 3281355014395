@import '../../common-style//variable.scss';


.radio-group
{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0;
}

.radio
{
  display: flex;
  gap: 5px;
}

.scrollToActivity > div {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.activity_page_main {
    .heading_filter_otr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        @media (max-width: 992px) {
            margin-bottom: 20px;
        }

        @media (max-width: 575px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }

        .heading_activity {
            color: $Black;
        }

        .filter_main {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .sort_text {
            color: $Black-65;
        }

        .form-select {
            flex: 1;
        }

        .filter_search_otr {
            display: flex;
            align-items: center;
            gap: 24px;

            @media (max-width: 575px) {
                width: 100%;
                gap: 16px;

                .Theme_btn_primary {
                    font-size: 14px;
                    padding: 8px 16px;
                }
            }

            .search_otr {
                @media (max-width: 575px) {
                    flex: 1;
                    width: 100%;
                }
            }

            .search_input {
                width: 280px;

                @media (max-width: 575px) {
                    width: 100%;
                    padding: 8px 16px;
                }
            }
        }
    }

    .row_custom {
        gap: 24px 0;

        @media (max-width: 992px) {
            gap: 16px 0;
        }

        .col_searchCard_otr {
            @media (max-width: 1200px) {
                flex: 0 0 auto;
                width: 33.33333333%;
            }

            @media (max-width: 992px) {
                padding-left: 8px;
                padding-right: 8px;
            }

            @media (max-width: 768px) {
                flex: 0 0 auto;
                width: 50%;
            }

            @media (max-width: 450px) {
                flex: 0 0 auto;
                width: 100%;
            }
        }

        .col_searchCard_inr {
            .edit_icon_otr {
                display: flex;
            }
        }
    }

    .action_otr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
    }
}