@import '../../common-style/variable.scss';

.BookingCon_Page {
    .BookingCon_main {
        padding: 112px 0 128px 0;

        @media (max-width: 575px) {
            padding: 48px 0;
        }
    }

    .content_wrapper {
        margin-bottom: 72px;

        @media (max-width: 575px) {
            margin-bottom: 48px;
        }
    }

    .row_custom {
        align-items: center;

        @media (max-width: 992px) {
            gap: 48px;
        }

        @media (max-width: 575px) {
            gap: 36px;
        }
    }

    .col_img_inr {
        .booking_conf_img {
            width: 100%;
            height: 500px;
            object-fit: fill;
            object-position: center;
        }
    }

    .col_content_inr {
        padding-left: 48px;

        @media (max-width: 992px) {
            padding-left: 0;
        }

        .booking_text {
            color: $Black-65;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 28px;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .booking_text_inr {
            color: $Black;
            margin-right: 10px;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 28px;
            }
        }

        .social_ul {
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 24px 0 36px 0;
        }

        .social_a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $White;
            box-shadow: $Box-Shadow-Custom;
            transition: .3s;

            &:hover {
                background-color: $Primary;

                .social_icon {
                    color: $White;
                }
            }

            .social_icon {
                font-size: 24px;
                line-height: 24px;
                color: $Primary;
                transition: .3s;
            }
        }

        .action_otr {
            display: inline-flex;
        }
    }
}




.confirmation-section {
    background: transparent linear-gradient(121deg,rgba(254,219,79,.122),rgba(255,22,0,.188) 100%,#f25042 0) 0 0 no-repeat padding-box;
    min-height: 70vh;
    width: 100%
}

.confirmation-section .content-block {
    margin-left: auto;
    margin-right: auto;
    max-width: 958px;
    padding-bottom: 100px;
    padding-top: 30px;
    width: 100%
}

.confirmation-section .content-block .title {
    color: #b41d6e;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.325;
    margin-bottom: 5px
}

.confirmation-section .content-block .sub-text {
    color: #321e14;
    font-size: 19px
}

.confirmation-section .content-block .sub-text, .confirmation-section .content-block .title {
    text-align: center
}

.confirmation-section .confirmation-card {
    align-items: center;
    background-color: #fed4c6;
    border-radius: 16px;
    display: grid;
    gap: 28px;
    grid-template-columns: 340px 1fr;
    margin-bottom: 36px;
    margin-top: 42px;
    width: 100%
}

.confirmation-section .confirmation-card .banner, .confirmation-section .confirmation-card .banner .img-container, .confirmation-section .confirmation-card .banner img {
    height: 100% !important;
    width: 100%
}

.confirmation-section .confirmation-card .banner img {
    border-radius: 16px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.confirmation-section .confirmation-card .card-body {
    padding-bottom: 32px;
    padding-right: 60px;
    padding-top: 30px
}

.confirmation-section .confirmation-card .info-list {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.confirmation-section .confirmation-card .info-list .info-item {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 24px 1fr;
    margin-bottom: 12px
}

.confirmation-section .confirmation-card .info-list .info-item .icon {
    display: inline-block;
    width: 100%
}

.confirmation-section .confirmation-card .info-list .info-item .text {
    color: #321e14;
    font-size: 22px;
    font-weight: 100;
    line-height: 1.1;
    margin-bottom: 0
}

.confirmation-section .confirmation-card .info-list hr {
    border-color: #f68446;
    border-top-width: 2px;
    opacity: 1
}

.confirmation-section .offer-info {
    background-color: #fff4ed;
    border-radius: 16px;
    color: #b41d6e;
    font-size: 28px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    padding: 20px;
    text-align: center;
    width: 100%
}

.confirmation-section .social-list {
    align-items: center;
    display: flex;
    gap: 22px;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding-bottom: 16px;
    padding-left: 0;
    padding-top: 24px
}

.confirmation-section .social-list a {
    display: inline-block
}

@media (max-width: 992px) {
    .confirmation-section .content-block .title {
        font-size: 30px
    }

    .confirmation-section .confirmation-card .info-list .info-item .text {
        font-size: 20px
    }

    .confirmation-section .offer-info {
        font-size: 22px
    }
}

@media (max-width: 780px) {
    .confirmation-section .confirmation-card {
        grid-template-columns: 1fr 1.45fr
    }

    .confirmation-section .confirmation-card .card-body {
        padding-right: 30px
    }
}

@media (max-width: 767px) {
    .confirmation-section .confirmation-card {
        grid-template-columns: 1fr
    }

    .confirmation-section .confirmation-card .card-body {
        padding-left: 30px;
        padding-top: 10px
    }

    .confirmation-section .confirmation-card .banner {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        text-align: center
    }

    .confirmation-section .confirmation-card .banner .img-container, .confirmation-section .confirmation-card .banner img {
        height: auto
    }
}

@media (max-width: 575px) {
    .confirmation-section .content-block {
        padding-bottom: 50px;
        padding-top: 20px
    }

    .confirmation-section .content-block .title {
        font-size: 20px
    }

    .confirmation-section .content-block .sub-text {
        display: none;
        font-size: 14px
    }

    .confirmation-section .confirmation-card {
        gap: 14px;
        margin-bottom: 15px;
        margin-top: 10px
    }

    .confirmation-section .confirmation-card .banner {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px
    }

    .confirmation-section .confirmation-card .banner img {
        max-height: 160px
    }

    .confirmation-section .confirmation-card .card-body {
        padding: 0 10px 5px
    }

    .confirmation-section .confirmation-card .info-list .info-item {
        margin-bottom: 6px
    }

    .confirmation-section .confirmation-card .info-list .info-item .text {
        font-size: 15px
    }

    .confirmation-section .confirmation-card .info-list hr {
        margin: 8px 0
    }

    .confirmation-section .offer-info {
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 8px
    }

    .confirmation-section .social-list {
        padding-top: 16px
    }

    .confirmation-section .social-list svg {
        height: auto;
        width: 35px
    }
}
