@import "../../common-style/variable.scss";

.contact_page {
  .Contact_main {
    padding: 128px 0;

    @media (max-width: 575px) {
      padding: 36px 0 48px 0;
    }

    .marquee_main_top {
      display: block;
    }
  }
}

// .container-landing {
//   height: 100%;
//   padding: 50px 100px;
// }

.body-landing {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  // padding: 50px 0px;
}

.item-landing {
    padding: 24px;
    border: 4px solid orange;
    border-radius: 4px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: $White;
    transition: .3s;
    height: 331px;
    margin-bottom: 10px;

    &:hover {
        box-shadow: $Box-Shadow-Custom;
        background-color: $Primary-Light2;
    }
}

.admin_landingpage {
    .admin_heading {
        margin-bottom: 24px;
    }

    .body-landing {
        display: flex;
        gap: 24px;

        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    .item-landing {
        width: 33.3333%;

        @media (max-width: 768px) {
            width: 47%;
        }

        @media (max-width: 480px) {
            width: 100%;
        }
    }

    .card_heading {
        margin-bottom: 30px;
        height: auto;
        font-size: 25px !important;
    }

    button {
        @media (max-width: 1200px) {
            padding: 12px 24px;
        }
    }
}