@import '../../common-style//variable.scss';



.downloadButton
{
cursor: pointer;
}

.sort_filter_container  select
{
   height : 52px
}


.partner_table {
    max-height: 492px;
    overflow-y: auto;

    .data_table {
        width: 100%;
    }

    .table_header {
        background-color: $Grey-Bg;
        border-bottom: 1px solid $Black-25;
        position: sticky;
        top: 0;
        left: 0;

        tr {

            th {
                padding: 8px 0;

                
                &:not(:last-child) {
                    .header_text {
                        border-right: 1px solid $Black-25;
                    }
                }
            }

            .header_text {
                color: $Black;
                padding: 0 10px 0 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }

    .table_body {
        tr {
            height: 72px;
            border-bottom: 1px solid $Black-25;
            padding: 8px 0;

            td {
                &:first-child {
                    .body_text {
                        color: $Black;
                    }
                }

                &:not(:last-child) {
                    .body_text {
                        border-right: 1px solid $Black-25;
                    }
                }
            }

            .body_text {
                color: $Black-65;
                padding: 5px 10px 5px 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .prodile_otr {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 0 10px 0 16px;
                border-right: 1px solid $Black-25;

                .profile_img {
                    width: 36px;
                    height: 36px;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 100%;
                }

                .profile_name {
                    color: $Black-65;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}