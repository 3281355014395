.btnOrder {
    border: 2px solid grey;
    background-color: #FEEAE0;
    border-radius: 8px;
    padding: 6px 14px;
    font-weight:bold;
    font-size:12px;
}
.filter_main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 20px 0;
}
.btnSearchIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 8px;
    background-color: #000000;
    cursor: pointer;
    transition: 0.3s;
    margin-left:10px;
}
.crcles {
    /*background-color:green;*/
    margin: 12px;
}
.crclDiv {

    border: 5px solid grey;
    border-radius: 50%;
    /*padding: 3rem 10px;*/
    display: flex;
    justify-content: center;
    align-items: center;
}
.circleContent {
    cursor: pointer;
    position: relative;
}
.iconHidden {
    /* Initially hide icons */
    display: none;
    font-size: 1.3rem;
    color: white;
    justify-content: space-around;
    position: absolute;
    z-index: 1;
    align-items: center;
    backdrop-filter: blur(5px);
    /* Other necessary styles */
}
    .iconHidden i{
        border-radius:50%;
        background-color:#FC9765;
    }
    .imgBx:hover .iconHidden {
        display: flex;
    }

.crclDivAll {
    height: 300px;
    width: 300px;
    border: 5px solid grey;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.divAllMain{
    display:grid;
    place-items:center
}
.dropdownCal {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dropdownCal i{
        font-size:1.3rem;
        margin:0 0 0 10px
}
.dropdown-contentCal {
        display: block;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        padding: 12px 16px;
        z-index: 1;
}
#pdfDivMain {
    margin:35px 0;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}

.searchResultContent {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    transition: 0.3s;
    transition: all 1s ease;
}

.searchResultContentMain{
    height:300px;
    width:300px;
    display:flex;
    align-items:center;
}
.nameDiv {
    border: 2px solid grey;
    border-radius: 10px;
    padding: 6px 4rem;
    margin: 1rem 0;
    background-color: #FFFFE0
}
.containerCircle {
    position: relative;
    width: 400px;
    height: 400px;
    border: 6px dashed #FC9765;
    border-radius: 50%;
    margin: 10px 0;
    background-color:#FEEAE0
}

    .containerCircle .iconCircle {
        position: relative;
        left: -50%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
    }

        .containerCircle .iconCircle .imgBx {
            position: absolute;
/*            width: 60px;
            height: 60px;*/
            border-radius: 50%;
            overflow: hidden;
            /*transform: rotate(calc(360deg / 12 * var(--i)));*/
            z-index: 100;
            overflow: hidden;
            cursor: pointer;
        }


.containerCircle .iconCircle .imgBx.active {
    box-shadow: 0 0 0 4px #222, 0 0 0 12px #ff1d50;
}

            .containerCircle .iconCircle .imgBx img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }

.contentCircle {
    position: absolute;
    inset: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px !important;
    zoom: 1.4 !important;
}

.contentBx {
    position: absolute;
    transform: scale(0);
    transition: 0.5s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}



.activity_li {
    padding-right: 4px;
    width: 102%;
    padding-top: 12px;
}

.flex-center {
    display: inline-flex;
}


.btnSearchIcon {
    margin-top: 12px;
}

/* Your existing styles for other elements */
/* Style for the refresh icon */
.refresh-icon {
    width: 20px; /* Set the width as needed */
    height: 20px; /* Set the height as needed */
    border: none;
    padding: 0;
    cursor: pointer;
    /* Add any additional styles based on your requirements */
    /* You can use pseudo-elements to create the appearance of a refresh icon */
    position: relative;
}

    .refresh-icon::before,
    .refresh-icon::after {
        content: '';
        position: absolute;
        background-color: #fff; /* Example color */
    }

    .refresh-icon::before {
        width: 2px;
        height: 12px;
        top: 4px;
        left: 9px;
        transform: rotate(45deg);
    }

    .refresh-icon::after {
        width: 2px;
        height: 12px;
        top: 4px;
        left: 9px;
        transform: rotate(-45deg);
    }


/* Add other styles as needed */


.funtlers-cards-container2 div {
    padding: 25px;
    background-color: #feeae0;
    border-radius: 50%;
    margin-top:-25px
}
.ins {
    margin-top:20px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #feeae063;
    width: 95.5%;
    text-align:center;
}

.swiper-button-prev:after {
    font-size: 24px !important;
    color: #fc9765 !important;
}

.swiper-button-next:after {
    font-size: 24px !important;
    color: #fc9765 !important;
}
.custom-btn, .custom-btn:hover {
    background: #ffffff !important;
    color: black !important;
    text-align: center !important;
    backdrop-filter: none !important
}


.disabled {
    cursor: not-allowed !important;
}
.disabled:after {
    color: #000000 !important;
}


.text-box {
    WIDTH: 100%;
    HEIGHT: 112PX;
    resize: none;
}

.my-f {
    display: inline-flex;
    width: 100%;
}

.VELG-sec
{
    padding-top:12px;
    gap:2px;
}

.VELG-sec button, .VELG-sec button:hover {
    border: 1px solid #ced4da;
    z-index: 0 !important;
}

.VELG-sec .form-control {
color: #000;
font-size: 20px;
padding: 6px;
text-align: center;
}


.header-circle {
    color: #f68446;
    z-index: 1000;
    position: absolute;
    border: 3px solid #f68446;
    padding: 5px 15px;
    background-color: #000000d4;
    text-transform: uppercase;
}

.f-1
{
    flex:1;
}

