.utility-dashboard {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: center;
    padding: 20px;
}

.utility-card {
    width: 218px;
    height: 218px;
    border-radius: 35px;
    padding: 27px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
}

.utility-icon {
    font-size: 40px;
    margin-bottom: 10px;
}


/* Back button styles */
.back-container {
    padding: 3px 25px;
}

.back-button {
    padding: 10px 20px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

    .back-button:hover {
        background-color: #1976D2;
    }

h1 {
    padding: 1px 27px;
}

h2 {
    margin-top: 20px;
    font-size: 30px;
    color: #333;
}

/* Style the back button with the arrow */
.back-button:before {
    content: '<';
    font-size: 20px;
    margin-right: 8px;
}


.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
