@import '../../common-style/variable.scss';

.about_page {
    .about_row_custom2 {
        flex-direction: row-reverse;
        margin-top: 128px;

        .col_content_inr {
            padding-right: 0;
            padding-left: 48px;
        }
    }
}

.container-about {
    margin:0 auto;

}
.container-about p {
    text-align:justify !important;

}