@import "../../common-style//variable.scss";

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0;
}
.radio {
  display: flex;
  gap: 5px;
}

.verifiedOrder {
  color: red !important;
  font-weight: bold !important;
}
.partner-page-button {
  width: 100px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  height: 50px;
}
.edit {
  background-color: red;
}
.activity {
  background-color: green;
}
.order {
  background-color: navy;
}
.email {
  background-color: gray;
}

.partner_row:hover {
  font-weight: bold;
  background-color: rgba(213, 200, 192, 0.177);
}

.partner_page_main {
  .heading_filter_otr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (max-width: 992px) {
      margin-bottom: 20px;
    }

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    .heading_activity {
      color: $Black;
    }

    .filter_search_otr {
      display: flex;
      align-items: center;
      gap: 24px;

      @media (max-width: 575px) {
        width: 100%;
        gap: 16px;

        .Theme_btn_primary {
          font-size: 14px;
          padding: 8px 16px;
        }
      }

      .search_otr {
        @media (max-width: 575px) {
          flex: 1;
          width: 100%;
        }
      }

      .search_input {
        width: 280px;

        @media (max-width: 575px) {
          width: 100% !important;
          padding: 8px 16px;
          flex: 1;
        }
      }
    }

    .filter_main {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .sort_text {
      color: $Black-65;
    }

    .form-select {
      flex: 1;
    }

    .search_input {
      padding-left: 46px;
      width: 280px !important;
    }

    .search_otr {
      position: relative;

      .search_icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        line-height: 20px;
        color: $Black-65;
      }
    }
  }

  .partner_table {
    overflow: auto;
    width: 100%;

    .partner_table_inr {
      width: calc(100% - 60px);
      min-width: 1024px;
    }

    .data_table {
      width: 100%;
    }

    .table_header {
      background-color: $Grey-Bg;
      border-bottom: 1px solid $Black-25;
      position: sticky;
      top: 0;
      left: 0;

      tr {
        th {
          padding: 8px 0;

          &:first-child {
            width: 110px;
          }

          &:nth-child(3) {
            width: 170px;
          }

          &:nth-child(4) {
            width: 130px;
          }

          &:nth-child(5) {
            width: 140px;
          }

          &:nth-child(6) {
            width: 160px !important;
          }

          &:last-child {
            width: 140px;
          }

          &:not(:last-child) {
            .header_text {
              border-right: 1px solid $Black-25;
            }
          }
        }

        .header_text {
          color: $Black;
          padding: 0 10px 0 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }

    .table_body {
      tr {
        height: 72px;
        border-bottom: 1px solid $Black-25;
        padding: 8px 0;

        td {
          &:first-child {
            .body_text {
              color: $Black;
            }
          }

          &:not(:last-child) {
            .body_text {
              border-right: 1px solid $Black-25;
            }
          }
        }

        .body_text {
          color: $Black-65;
          padding: 5px 10px 5px 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .prodile_otr {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 0 10px 0 16px;
          border-right: 1px solid $Black-25;

          .profile_img {
            width: 36px;
            height: 36px;
            object-fit: cover;
            object-position: center;
            border-radius: 100%;
          }

          .profile_name {
            color: $Black-65;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .action_otr {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 0 10px 0 16px;

          .icon {
            font-size: 18px;
            line-height: 18px;
            color: $Black-40;
            transition: 0.3s;

            &:hover {
              color: $Black;
            }
          }
        }
      }
    }
  }
}


/* Style for the overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the overlay is on top of other elements */
}

/* Style for the popup container */
.popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 90%;
    max-height: 90%;
    overflow: auto; /* Enable scrolling if content exceeds the size of the popup */
}