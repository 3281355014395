.edit_functional{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.edit_functional h2{
    text-transform: capitalize;
}
.edit_functional table{
    margin-top: 20px;
}
.edit_functional table tr th{
    text-align: center;
    padding: 10px;
}
.edit_functional table tr td{
    padding: 10px;
}