@import "../../common-style/variable.scss";

// .header_button {
//   width: 200px;
// }

.backdrop {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(8px);
}

.header_main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 28px 0;
  z-index: 999;

  @media (max-width: 992px) {
    padding: 16px 0;
  }

  .container-fluid {
    @media (max-width: 1500px) {
      padding: 0 20px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo_otr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .logo {
    width: 270px;
    height: 52px;
    object-fit: contain;
    object-position: left;

    @media (max-width: 1500px) {
      width: 240px;
      height: 44px;
    }

    @media (max-width: 992px) {
      width: 180px;
    }
  }

  .logo_text {
    color: $White;
    text-transform: capitalize;

    @media (max-width: 575px) {
      display: none;
    }
  }

  .action_menu_otr {
    display: flex;
    align-items: center;
    gap: 48px;

    @media (max-width: 1500px) {
      gap: 24px;
    }
  }

  .menu_ul {
    display: flex;
    align-items: center;
    gap: 40px;

    @media (max-width: 1500px) {
      gap: 30px;
    }

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .menu_li {
    overflow: hidden;
  }

  .menu_a {
    position: relative;
    color: $White;
    padding-bottom: 4px;
    transition: 0.3s;

    &:hover {
      &::after {
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: -100%;
      bottom: 0;
      width: 70%;
      height: 4px;
      background-color: $White;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
    }
  }

  .action_otr {
    display: flex;
    align-items: center;
    gap: 24px;

    button {
      padding: 14px 24px;

      @media (max-width: 1500px) {
        padding: 10px 24px;
      }

      @media (max-width: 1200px) {
        display: none;
      }
    }
  }

  .menu_icon_otr {
    display: none;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 4px;
    background-color: $White;
    transition: 0.3s;

    @media (max-width: 1200px) {
      display: flex;
    }

    &:hover {
      background-color: $Primary;

      .menu_icon {
        color: $White;
      }
    }

    .menu_icon {
      font-size: 20px;
      line-height: 20px;
      color: $Primary;
      transition: 0.3s;
    }
  }
}

.overlay_main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  &.overlay_open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;

    &::after {
      left: 0;
    }

    .overlay_wrapper {
      left: 0;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    left: -100%;
    top: 0;
    background-color: $Black-25;
    transition: 0.3s;
  }

  .overlay_wrapper {
    position: relative;
    max-width: 350px;
    width: 100%;
    height: 100vh;
    background-color: $White;
    z-index: 2;
    padding: 24px 16px;
    left: -350px;
    transition: 0.3s;
    overflow-y: auto;

    .logo_close_otr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $Black-10;
      padding-bottom: 24px;
    }

    .logo {
      width: 180px;
      height: 44px;
      object-fit: contain;
      object-position: left;
    }

    .close_icon_otr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background-color: $Primary;
      transition: 0.3s;

      &:hover {
        background-color: $Primary-Dark;

        .close_icon {
          color: $White;
        }
      }

      .close_icon {
        font-size: 20px;
        line-height: 20px;
        color: $White;
        transition: 0.3s;
      }
    }

    .action_menu_otr {
      padding-top: 14px;

      .menu_ul {
        margin-bottom: 36px;
      }

      .menu_a {
        color: $Black-65;
        padding: 10px 0;
        border-bottom: 1px solid $Black-10;
        transition: 0.3s;

        &:hover {
          color: $Primary;
          border-bottom: 1px solid $Primary;
        }
      }

      .action_otr {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;

        button {
          padding: 10px 16px;
          width: 100%;
        }

        .login_btn {
          width: 100%;
        }

        .register_btn_otr {
          width: 100%;

          button {
            background-color: $Primary-Light2;
          }
        }
      }
    }
  }
}



.top-navbar {
    background: transparent linear-gradient(94deg,#fffae8,#fed3ce 100%,#f25042 0) 0 0 no-repeat padding-box;
    padding-bottom: 18px;
    padding-top: 16px;
    width: 100%;
    z-index: 1000
}

.top-navbar .navbar-brand {
    max-width: 213px;
    width: 100%
}

.top-navbar .navbar-brand img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

.top-navbar .navbar-nav .nav-item {
    background-color: #f68446;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(72,72,72,.161);
    margin: auto;
    max-width: 159px;
    padding: 0;
    width: 100%
}

.top-navbar .navbar-nav .nav-item:not(:last-child) {
    margin-right: 17px
}

.top-navbar .navbar-nav .nav-item .nav-link {
    color: #ffeeec;
    font-size: 18px;
    font-weight: 500;
    padding: 11px 15px;
    background-color: transparent;
    border: 0px;
}

.top-navbar .navbar-nav .nav-item .dropdown .dropdown-menu .dropdown-item {
    font-size: 16px;
    font-weight: 500
}

.top-navbar .navbar-nav .nav-item .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: rgba(246,132,70,.18)
}

.top-navbar .btn {
    border: 0;
    border-radius: 4px;
    padding: 11.5px 12px
}

.dropdown-menu {
    left: -95px;
    top: 52px;
}


@media (max-width: 575px) {
    .top-navbar {
        padding-bottom: 11px;
        padding-top: 10px
    }

    .top-navbar .navbar-toggler {
        padding: 7px 11px
    }

    .top-navbar .navbar-nav .nav-item:not(:last-child) {
        margin-right: 4px
    }

    .top-navbar .navbar-nav .nav-item .nav-link {
        font-size: 12px;
        padding: 7px 5px;
        text-align: center
    }

    .top-navbar .navbar-nav .nav-item .dropdown .dropdown-menu .dropdown-item {
        font-size: 12px;
        padding: 5px 10px
    }

    .top-navbar .btn {
        padding: 4px 2px
    }

    .top-navbar .btn svg {
        padding: 4px
    }

    .top-navbar .navbar-brand img {
        width: 77%;
    }
}

