@import '../../common-style/variable.scss';


@media (max-width: 1920px) {
    .under_Goye {
        font-size: 70px !important;
    }

    .activitet_currently h4 {
        font-size: 70px !important;
    }

    .header_content h1 {
        font-size: 70px !important;
    }

    .discountbubble {
        bottom: -23px !important;
    }

    .aside .bubbles:nth-child(1) {
        transform: translateY(-210px) !important;
    }

    .aside .bubbles:nth-child(2) {
        transform: translateY(-3px) !important;
    }
}

.hero-section .content-container .cards-content .cards-container .card-block .description {
    gap: 20px;
}

.bubbles {
    position: relative;
    cursor: pointer;
}

.discountbubble {
    border-radius: 50%;
    background-color: green;
    width: 92px;
    height: 92px;
    padding: 10px;
    display: flex;
    color: white;
    bottom: 10px;
    right: 10px;
    position: absolute;
    align-items: center;
    justify-content: center;
    animation: appendd 1.3s ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.aside .bubbles:nth-child(1) {
    transform: translateY(-243px);
}

.aside .bubbles:nth-child(2) {
    transform: translateY(-139px) !important
}

@keyframes appendd {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 1763px) {
    .hero_header .Hero_main .col_team_building_otr {
        margin: -138px auto 170px auto !important;
        padding: 66px !important;
    }

    .hero_header .aside img:nth-child(1) {
        margin-bottom: 35px !important;
        margin-top: 30px !important;
    }

    .hero_header .aside img:nth-child(2) {
        margin-bottom: 351px !important;
    }

    .hero_header .aside img {
        width: 373px;
    }

    .discountbubble {
        bottom: 162px !important;
    }

    .aside .bubbles:nth-child(1) {
        transform: translateY(-93px) !important;
    }

    .aside .bubbles:nth-child(2) {
        transform: translateY(-110px) !important;
    }
}

@media (max-width: 1640px) {
    .hero_header .aside {
        width: 20% !important;
    }

    .Hero_main {
        width: 50% !important;
    }

    .hero_header .Hero_main .col_team_building_inr .col_inpiration_inr .inspiration_ul {
        gap: 8px !important
    }

    .hero_header .Hero_main .col_team_building_otr {
        margin: -78px auto 8px auto !important;
    }

    .hero_header .aside img {
        width: 356px !important;
    }

    .hero_header .aside img:nth-child(1) {
        margin-bottom: -11px !important
    }

    .hero_header .aside img:nth-child(2) {
        margin-bottom: 137px !important
    }

    .discountbubble {
        bottom: 87px !important;
    }

    .aside .bubbles:nth-child(1) {
        transform: translateY(-77px);
    }

    .aside .bubbles:nth-child(2) {
        transform: translateY(0) !important;
    }
}

@media (max-width: 1222px) {
    .thrios {
        flex-direction: column;
        align-items: center;
    }

    .hero_header .Hero_main .col_team_building_otr {
        padding: 0px 0px 100px 0px !important;
    }

    .Hero_main {
        width: 50% !important;
    }

    .aside {
        width: 100% !important;
        margin-top: 40px;
        align-items: center !important;
    }

    .hero_header .aside img {
        width: 504px !important;
    }

    .hero_header .aside img:nth-child(2) {
        margin-bottom: 104px !important;
    }

    .hero_header .aside img:nth-child(1) {
        margin-bottom: 66px !important;
    }

    .discountbubble {
        bottom: 248px !important;
    }
}

@media (max-width: 486px) {
    .hero_header .aside img {
        width: 319px !important;
    }

    .under_Goye {
        font-size: 40px !important;
    }

    .activitet_currently h4 {
        font-size: 40px !important;
    }

    .header_content h1 {
        font-size: 40px !important;
    }

    .Hero_main {
        width: 90% !important;
    }

    .hero_header .Hero_main .col_team_building_otr {
        padding: 0px 0px 14px 0px !important
    }

    .bubbles {
        display: none;
    }

    .thrio {
        margin-top: 20px !important;
        margin-bottom: 21px !important;
    }

    .activitet_currently {
        transform: translateY(0px) !important;
    }
}

.hero_header {
    position: relative;
    background-image: url(../../img/hero-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 110px;



    .aside {
        width: 25%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }

    .aside:nth-child(1) {
        @media (max-width: 1920px) {
            align-items: end;
        }
    }

    .aside img {
        width: 471px;
        aspect-ratio: 16/9;
        border-radius: 20px;

        @media (max-width: 1920px) {
            width: 411px;
        }
    }


    .aside img:nth-child(1) {
        margin-bottom: -153px;

        @media (max-width: 1920px) {
        }
    }


    .aside img:nth-child(2) {
        margin-bottom: 57px;

        @media (max-width: 1920px) {
        }
    }


    .bubble {
        animation: __bounceInDown 2s ease;
    }

    @keyframes __bounceInDown {
        0% {
            opacity: 0;
            transform: scale(.3);
        }

        50% {
            opacity: 1;
            transform: scale(1.05);
        }

        70% {
            transform: scale(.9);
        }

        100% {
            transform: scale(1);
        }
    }

    .Hero_main {
        position: relative;
        width: 50%;

        @media (max-width: 575px) {
            display: flex;
            flex-direction: column-reverse;
        }

        .col_team_building_otr {
            margin: -78px auto 170px auto;
            padding: 100px;

            @media (max-width: 1200px) {
                margin: 48px 24px 128px 0;
            }

            @media (max-width: 992px) {
                margin: 24px 0 48px 0;
            }

            @media (max-width: 450px) {
                margin: 0 0 48px 0;
            }
        }

        .activitet_currently h4 {
            color: white !important;
            font-weight: 700;
        }

        .thrio {
            margin-top: 60px;
            margin-bottom: 59px;
            width: 100%;
            justify-content: space-around;
        }

        .header_content img:nth-child(1) {
            width: 212px;
            aspect-ratio: 1/1;
            border-radius: 50%
        }

        .header_content:nth-child(2) {
            display: flex;
            width: 300px;
            text-align: center;
            justify-content: center;
            flex-direction: column;
        }

        .header_content {
            color: #f5f5f5 !important;
        }

        .header_content:nth-child(2) h1 {
            font-size: 100px;
            font-weight: 700;
            transform: translateY(-58px);
        }

        .header_content:nth-child(1) img {
            transform: rotateZ(336deg);
        }

        .header_content:nth-child(3) img {
            transform: rotateZ(21deg);
        }

        .col_team_building_inr {

            .inpiration_teambuilding {
                padding: 30px 24px 24px 24px;
                border-radius: 16px;
                background-color: $Primary-Light2;

                @media (max-width: 575px) {
                    padding: 20px;
                }
            }

            .promotes {
                padding: 15px 24px 24px 24px;
                border-radius: 16px;
                background-color: $Primary-Light2;

                @media (max-width: 575px) {
                    padding: 10px;
                }
            }

            .heading_market {
                font-size: 28px;
                color: $White;
                margin-bottom: 24px;
            }

            .heading_market_custom {
                font-size: 24px;
                color: $White;
                margin-bottom: 16px;

                br {
                    display: none;

                    @media (max-width: 575px) {
                        display: block;
                    }
                }
            }

            .search_otr {
                position: relative;
                margin: 20px 0 24px 0;
            }

            .search_input {
                padding-right: 52px;
                box-shadow: none;

                &::-webkit-search-cancel-button {
                    display: none;
                }
            }

            .search_icon_otr_local {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
                border-radius: 0 8px 8px 0;
                background-color: $Primary;
                cursor: pointer;
                transition: .3s;

                @media (max-width: 450px) {
                    height: 40px;
                }

                &:hover {
                    background-color: $Primary-Dark;
                }

                @media (max-width: 1440px) {
                    border-radius: 4px;
                }
            }

            .search_icon_local {
                font-size: 24px;
                color: $White;
                background-color: transparent;
                border: none;
                outline: none;
                width: 100%;
            }

            .activity_main {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $White;
                border-radius: 8px;
                padding: 0px 0px 0px 16px;
                gap: 10px;

                @media (max-width: 450px) {
                    padding: 0;
                    align-items: flex-start;
                    gap: 0px;
                }
            }

            .activity_ul {
                display: grid;
                grid-template-columns: auto auto auto auto;
                flex: 1;

                @media (max-width: 450px) {
                    width: 100%;
                }

                .activity_li {
                    @media (max-width:450px) {
                        .Theme_select {
                            font-size: 10px !important;
                        }
                    }

                    &:not(:first-child) {
                        .Theme_select {
                            padding-left: 10px;
                        }
                    }

                    @media (max-width: 450px) {
                        border-bottom: 1px solid $Black-25;

                        .Theme_select {
                            padding-left: 10px;
                        }
                    }
                }

                .Theme_select {
                    font-size: 14px;
                    font-family: $font-family-SemiBold;
                    background-color: transparent;
                    box-shadow: none;
                    border-right: 1px solid $Black-25;
                    background-position: right 0.30rem center;
                    border-radius: 0;
                    color: $Black-65;
                    padding: 6px 24px 6px 0;
                    cursor: pointer;
                }
            }

            .filter_otr {
                .Theme_select {
                    background-color: transparent;
                    font-size: $font-size-16px;
                    font-family: $font-family-SemiBold;
                    box-shadow: none;
                    border-left: 1px solid $Black-25;
                    border-radius: 0;
                    color: $Black-65;
                    padding: 6px 30px 6px 16px;
                    background-position: right 0.25rem center;
                    background-image: url(../../img/filter-icon.svg);
                    background-size: 20px 20px;
                    cursor: pointer;

                    @media (max-width: 1440px) {
                        border-left: none;
                        border-right: 1px solid $Black-25;
                        padding: 6px 40px 6px 0px;
                        background-position: right 0.65rem center;
                    }
                }
            }

            .relevent_select_otr {
                display: flex;
                align-items: center;
                justify-content: center;

                .Theme_select {
                    font-size: $font-size-16px;
                    font-family: $font-family-SemiBold;
                    width: auto;
                    box-shadow: none;
                    padding: 12px 40px 12px 24px;
                    background-color: $White;
                    color: $Black-65;
                    background-position: right 0.85rem center;
                    cursor: pointer;
                }
            }

            .activity-container {
                margin: 16px 0;
            }

            .col_inpiration_inr {
                .heading {
                    color: $Black;
                    margin-bottom: 16px;
                }

                .inspiration_ul {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 16px;
                    justify-content: center;

                    @media (max-width: 992px) {
                        gap: 12px;
                    }

                    @media (max-width: 575px) {
                        gap: 8px;
                        grid-template-columns: auto auto auto auto;
                    }

                    @media (max-width: 480px) {
                        grid-template-columns: auto auto auto;
                    }

                    @media (max-width: 380px) {
                        grid-template-columns: auto auto;
                    }
                }

                .inspiration_btn {
                    border: none;
                    padding: 0px 10px;
                    cursor: pointer;
                    outline: none;
                    min-width: 120px;
                    max-width: 120px;
                    height: 45px;
                    width: auto;

                    @media (max-width: 575px) {
                        font-size: $font-size-12px;
                        line-height: $line-height-20px;
                        width: 100%;
                        padding: 6px 10px;
                        height: auto;
                        max-width: 100%;
                        min-width: auto;
                    }
                }
            }
        }

        .Heading {
            color: $White;
            text-transform: uppercase;
        }

        .heading_inr {
            display: inline-block;
            position: relative;
            z-index: 2;
        }

        .circle_text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 330px;
            height: 72px;
            object-fit: contain;
            z-index: -1;
        }

        .desc {
            color: $White;
            margin: 20px 0 40px 0;
        }

        .action_otr {
            display: flex;
        }

        .hero_img_otr {
            position: absolute;
            right: 0;
            top: 48%;
            transform: translateY(-50%);
            width: 40%;
            height: calc(100% - 100px);
            border-radius: 22px;

            @media (max-width: 1200px) {
                width: 35%;
                right: 48px;
            }

            @media (max-width: 992px) {
                position: inherit;
                width: 100%;
                right: inherit;
                top: inherit;
                transform: translateY(0);
                height: 450px;
                padding-bottom: 48px;
            }

            @media (max-width: 575px) {
                height: 400px;
            }

            @media (max-width: 450px) {
                height: 320px;
                padding-bottom: 30px;
            }
        }

        .hero_img {
            height: 100%;
            /* object-fit: contain; */
            aspect-ratio: 3/3;
            border-radius: 30px;
            width: 515px;

            @media(max-width: 1200px) {
                height: 400px;
                width: 100%;
                margin-top: 18px;
            }

            @media(max-width: 992px) {
                height: 338px;
                width: 392px;
            }

            @media(max-width: 575px) {
                height: 285px;
                width: 310px;
            }

            @media(max-width: 448px) {
                height: 230px;
                width: 267px;
            }
        }

        .hero_swiper,
        .swiper-wrapper,
        .swiper-slide,
        .img_otr {
            height: 100%;
        }

        .hero_swiper {
            padding-bottom: 50px;
            padding-left: calc(100% - 779px);

            @media(max-width: 2070px) {
                padding-left: calc(100% - 699px)
            }

            @media(max-width: 1870px) {
                padding-left: calc(100% - 636px)
            }

            @media(max-width: 1600px) {
                padding-left: calc(100% - 593px)
            }

            @media(max-width: 992px) {
                padding-left: 30%;
            }

            @media(max-width: 758px) {
                padding-left: 22%;
            }

            @media(max-width: 540px) {
                padding-left: 19%;
            }

            @media(max-width: 338px) {
                padding-left: 17px;
            }
        }

        .swiper-slide {
            opacity: 0;
            visibility: hidden;
            transition-duration: 3000ms !important;
        }

        .swiper-slide-visible,
        .swiper-slide-active {
            opacity: 1;
            visibility: visible;
        }

        .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0px;
            gap: 4px;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $Black-25;
            margin: 0;
            opacity: 1;
        }

        .swiper-pagination-bullet-active {
            background-color: $Primary-Dark;
            opacity: 1;
        }
    }

    .body_building_otr {
        display: flex;
        justify-content: center;
    }
}

@media (max-width:450px) {
    .last .Theme_select {
        border-right: none !important;
    }
}



.hero-section {
    background-image: url(../../img/rain-bow.png?2ff8a4179721f962ca4dc02a5136db48);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 96%;
    padding-bottom: 58px;
    padding-top: 55px;
    position: relative;
    width: 100%;
    z-index: 1
}

.hero-section:before {
    background: transparent linear-gradient(94deg,rgba(254,219,79,.122),rgba(255,22,0,.188) 100%,#f25042 0) 0 0 no-repeat padding-box;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1
}

.hero-section .content-container {
    display: grid;
    gap: 60px;
    grid-template-columns: 1.25fr 1fr;
    justify-content: center
}

.hero-section .content-container .text-content .title {
    color: #321e14;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 15px
}

.hero-section .content-container .text-content .title .highlights {
    color: #b41d6e !important
}

.hero-section .content-container .text-content .text-details {
    color: #321e14;
    font-size: 24px;
    width: 100%
}

.hero-section .content-container .text-content .sub-title {
    color: #321e14;
    font-size: 43px;
    font-weight: 600;
    margin-top: 22px;
    text-align: center
}

.hero-section .content-container .text-content .details-container {
    align-items: center;
    display: grid;
    gap: 10px 8px;
    grid-template-columns: repeat(auto-fill,minmax(82px,1fr));
    justify-items: center;
    padding-bottom: 10px;
    padding-top: 20px
}

.hero-section .content-container .text-content .details-container .detail-block {
    height: 100%;
    text-align: center;
    width: 130%
}

.hero-section .content-container .text-content .details-container .detail-block .icon-container {
    align-items: center;
    aspect-ratio: 1/1;
    background-color: #f68446;
    border-radius: 50%;
    display: flex;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    place-content: center;
    place-items: center;
    width: 45px
}

.hero-section .content-container .text-content .details-container .detail-block .detail-info {
    color: #321e14;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 0
}

.hero-section .content-container .cards-content .cards-container {
    align-items: flex-start;
    display: flex;
    gap: 30px;
    justify-content: center;
    z-index: 1
}

.hero-section .content-container .cards-content .cards-container .card-block {
    text-decoration:none;
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 18px;
    box-shadow: 0 18px 18px rgba(242,80,66,.169);
    max-width: 285px;
    padding: 10px;
    width: 100%
}

.card-block a {
    color: #70625d !important;
}

.hero-section .content-container .cards-content .cards-container .card-block:last-child {
    margin-top: 120px
}

.hero-section .content-container .cards-content .cards-container .card-block .img-container {
    margin-bottom: 6px
}

.hero-section .content-container .cards-content .cards-container .card-block .img-container img {
    border-radius: 8px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

.hero-section .content-container .cards-content .cards-container .card-block .text-block {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%
}

.hero-section .content-container .cards-content .cards-container .card-block .lbl-title {
    color: #321e14;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    max-width: 285px;
}

.hero-section .content-container .cards-content .cards-container .card-block .text-block .details .lbl {
    color: #321e14;
    font-size: 10.5px
}

.hero-section .content-container .cards-content .cards-container .card-block .text-block .details .bottom-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0
}

.hero-section .content-container .cards-content .cards-container .card-block .text-block .btn-block {
    background-color: #b41d6e;
    border-radius: 8px;
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    padding: 15px 10px;
    text-align: center
}

.hero-section .content-container .cards-content .cards-container .card-block .text-block .learn-more {
    background-color: #b41d6e;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 17px;
    text-align: center
}

.hero-section .content-container .cards-content .cards-container .card-block .text-block:not(:last-child) {
    margin-bottom: 6px
}

.funtlers-card-section {
    padding-bottom: 120px;
    padding-top: 85px
}

.funtlers-card-section .card-info-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1820px;
    position: relative;
    width: 100%
}

.funtlers-card-section .card-info-container .swiper-button-next, .funtlers-card-section .card-info-container .swiper-button-prev {
    align-items: center;
    background: #f4e0d2 0 0 no-repeat padding-box;
    border-radius: 50%;
    color: #f68446;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    position: absolute;
    width: 50px;
    z-index: 10
}

.funtlers-card-section .card-info-container .swiper-button-next:after, .funtlers-card-section .card-info-container .swiper-button-prev:after {
    font-size: 18px;
    font-weight: 600
}

.funtlers-card-section .card-info-container .swiper-button-prev {
    left: 0
}

.funtlers-card-section .card-info-container .swiper-button-next {
    right: 0
}

.funtlers-card-section .top-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 46px
}

.funtlers-card-section .top-content .title {
    color: #321e14;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 15px
}

.funtlers-card-section .top-content .detail-text {
    color: #321e14;
    font-size: 18px;
    line-height: 1.5;
    max-width: 726px;
    text-align: center;
    width: 100%
}

.funtlers-card-section .funtlers-cards-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1790px;
    padding-left: 85px;
    padding-right: 85px;
    width: 100%
}

.funtlers-card-section .funtlers-cards-container .swiper-wrapper {
    align-items: center
}

.funtlers-card-section .funtlers-cards-container .card-block {
    border: 2px solid #707070;
    border-radius: 18px;
    box-shadow: 0 6px 14px rgba(0,0,0,.078);
    padding: 13px 13px 25px
}

.funtlers-card-section .funtlers-cards-container .card-block .img-container {
    margin-bottom: 12px
}

.funtlers-card-section .funtlers-cards-container .card-block .img-container img {
    border-radius: 8px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

.funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block {
    margin-bottom: 17px
}

.funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .title-desc {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #321e14;
    display: -webkit-box;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis
}

.funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .description {
    color: #321e14;
    font-size: 18px;
    margin-bottom: 0;
    height: 95px !important;
}

.funtlers-card-section .funtlers-cards-container .card-block .text-description .btn-container {
    display: flex;
    justify-content: center
}

.funtlers-card-section .funtlers-cards-container .card-block .text-description .btn-container .btn {
    background-color: #b41d6e;
    border-radius: 8px;
    color: #fff !important;
    font-size: 18px;
    padding: 9px 12px;
    text-align: center;
    text-transform: capitalize
}

@media (max-width: 1700px) {
    .hero-section .content-container {
        gap: 80px
    }

    .hero-section .content-container .text-content .title {
        font-size: 48px
    }

    .hero-section .content-container .text-content .sub-title {
        font-size: 38px
    }
}

@media (max-width: 1400px) {
    .hero-section .content-container {
        gap: 40px
    }

    .hero-section .content-container .text-content .title {
        font-size: 38px
    }

    .hero-section .content-container .text-content .text-details {
        font-size: 19px;
        max-width: 620px
    }

    .funtlers-card-section .top-content .title, .hero-section .content-container .text-content .sub-title {
        font-size: 34px
    }
}

@media (max-width: 1200px) {
    .hero-section {
        background-position: 100% 0;
        background-size: contain
    }

    .hero-section .content-container {
        grid-template-columns: 1fr
    }

    .hero-section .content-container .text-content {
        order: 2
    }

    .hero-section .content-container .text-content .title {
        font-size: 42px
    }

    .hero-section .content-container .text-content .text-details {
        font-size: 20px;
        max-width: 100%
    }

    .hero-section .content-container .text-content .sub-title {
        font-size: 32px
    }

    .hero-section .content-container .cards-content {
        order: 1
    }

    .hero-section .content-container .cards-content .cards-container {
        gap: 20px
    }

    .hero-section .content-container .cards-content .cards-container .card-block:last-child {
        margin-top: 70px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .details .bottom-title {
        font-size: 18px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .btn-block {
        font-size: 22px;
        padding: 12px 7px
    }

    .funtlers-card-section {
        padding-bottom: 85px
    }

    .funtlers-card-section .top-content {
        margin-bottom: 32px
    }

    .funtlers-card-section .top-content .title {
        font-size: 32px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .title-desc {
        font-size: 22px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .description {
        font-size: 16px;
        line-height: 1.3
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .btn-container .btn {
        font-size: 16px
    }
}

@media (max-width: 991px) {
    .hero-section {
        padding-bottom: 65px;
        padding-top: 65px
    }

    .hero-section .content-container .text-content .title {
        font-size: 38px
    }

    .hero-section .content-container .text-content .sub-title {
        font-size: 28px
    }

    .hero-section .content-container .text-content .details-container {
        gap: 32px 6px
    }

    .hero-section .content-container .cards-content .cards-container .card-block:last-child {
        margin-top: 50px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .details .bottom-title {
        font-size: 18px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .btn-block {
        font-size: 22px;
        padding: 12px 7px
    }

    .funtlers-card-section {
        padding-bottom: 75px;
        padding-top: 65px
    }

    .funtlers-card-section .top-content {
        margin-bottom: 26px
    }

    .funtlers-card-section .top-content .title {
        font-size: 28px;
        margin-bottom: 12px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block {
        margin-bottom: 14px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .title-desc {
        font-size: 20px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .description {
        font-size: 16px
    }
}

@media (max-width: 767px) {
    .hero-section {
        background-position: right -12%;
        background-size: 140%;
        padding-bottom: 55px;
        padding-top: 55px
    }

    .hero-section .content-container {
        justify-items: center
    }

    .hero-section .content-container .text-content .title {
        font-size: 32px
    }

    .hero-section .content-container .text-content .text-details {
        font-size: 18px
    }

    .hero-section .content-container .text-content .sub-title {
        font-size: 24px
    }

    .hero-section .content-container .text-content .details-container .detail-block .icon-container {
        margin-bottom: 6px
    }

    .hero-section .content-container .text-content .details-container .detail-block .detail-info {
        font-size: 14px
    }

    .hero-section .content-container .cards-content .cards-container .card-block:last-child {
        margin-top: 0
    }

    .hero-section .content-container .cards-content .cards-container .card-block .lbl-title {
        font-size: 15px;
        max-width: 285px;
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .details .bottom-title {
        font-size: 16px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .btn-block {
        font-size: 20px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .learn-more {
        font-size: 13px;
        padding: 5px 13px
    }

    .funtlers-card-section {
        padding-bottom: 65px;
        padding-top: 55px
    }

    .funtlers-card-section .card-info-container {
        max-width: 1787px
    }

    .funtlers-card-section .card-info-container .swiper-button-next, .funtlers-card-section .card-info-container .swiper-button-prev {
        height: 40px;
        width: 40px
    }

    .funtlers-card-section .card-info-container .swiper-button-next:after, .funtlers-card-section .card-info-container .swiper-button-prev:after {
        font-size: 16px
    }

    .funtlers-card-section .top-content {
        margin-bottom: 22px
    }

    .funtlers-card-section .top-content .title {
        font-size: 24px
    }

    .funtlers-card-section .top-content .detail-text {
        font-size: 16px
    }

    .funtlers-card-section .funtlers-cards-container {
        padding-left: 70px;
        padding-right: 70px
    }

    .funtlers-card-section .funtlers-cards-container .card-block {
        padding: 10px 10px 18px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .title-desc {
        font-size: 18px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .btn-container .btn, .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .description {
        font-size: 14px
    }
}

@media (max-width: 575px) {
    .hero-section {
        background-image: url(../../img/rain-bow-mbl.png?f0a296fc21949744a031cd05559533f8);
        background-position: right 38px;
        background-size: 190%;
        padding-top: 25px
    }

    .hero-section .content-container {
        gap: 25px
    }

    .hero-section .content-container .mbl {
        order: 1
    }

    .hero-section .content-container .text-content {
        order: 3;
        width: 100%
    }

    .hero-section .content-container .text-content .title {
        font-size: 23px;
        margin-bottom: 0;
        text-align: center
    }

    .hero-section .content-container .text-content .text-details {
        display: none
    }

    .hero-section .content-container .text-content .sub-title {
        font-size: 18px;
        margin-top: 8px;
        text-align: center
    }

    .hero-section .content-container .text-content .details-container {
        gap: 15px 6px;
        grid-template-columns: repeat(auto-fill,minmax(74px,1fr));
        padding-top: 8px
    }

    .hero-section .content-container .text-content .details-container .detail-block .icon-container {
        margin-bottom: 6px;
        width: 40px
    }

    .hero-section .content-container .text-content .details-container .detail-block .icon-container .icon svg {
        padding: 3px
    }

    .hero-section .content-container .text-content .details-container .detail-block .detail-info {
        font-size: 12px
    }

    .hero-section .content-container .cards-content {
        order: 2
    }

    .hero-section .content-container .cards-content .cards-container {
        flex-direction: row;
        gap: 7px
    }

    .hero-section .content-container .cards-content .cards-container .card-block {
        max-width: 150px;
        padding: 6px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .description {
        align-items: center;
        display: flex !important;
        gap: 2px !important;
        justify-content: center
    }

    .description .text-block:first-child {
        display: none;
    }


    .hero-section .content-container .cards-content .cards-container .card-block .text-block {
        display: block
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block:not(:last-child) {
        margin-bottom: 0
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .details {
        display: none
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .btn-block {
        font-size: 13px;
        padding: 5px 4px
    }

    .hero-section .content-container .cards-content .cards-container .card-block .text-block .learn-more {
        font-size: 13px;
        padding: 5px;
        white-space: nowrap;
        width: 100%
    }

    .funtlers-card-section .top-content .title {
        font-size: 20px
    }

    .funtlers-card-section .top-content .detail-text {
        font-size: 14px
    }

    .funtlers-card-section .funtlers-cards-container {
        padding-left: 0;
        padding-right: 0
    }

    .funtlers-card-section .funtlers-cards-container .card-block {
        border-radius: 10px;
        padding: 6px 6px 10px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .img-container img {
        border-radius: 5px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .title-desc {
        font-size: 14px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .text-block .description {
        font-size: 12px
    }

    .funtlers-card-section .funtlers-cards-container .card-block .text-description .btn-container .btn {
        font-size: 12px;
        padding: 6px 4px
    }

    .funtlers-card-section .card-info-container .mbl-swiper-btn {
        display: flex;
        gap: 9px;
        justify-content: center;
        padding-top: 10px
    }

    .funtlers-card-section .card-info-container .swiper-button-next, .funtlers-card-section .card-info-container .swiper-button-prev {
        height: 35px;
        margin: 0;
        position: relative;
        width: 35px
    }

    .funtlers-card-section .card-info-container .swiper-button-next:after, .funtlers-card-section .card-info-container .swiper-button-prev:after {
        font-size: 14px
    }
}

.activity-section .content-block {
    display: grid;
    gap: 20px;
    grid-template-columns: 1.482fr 1fr;
    margin-left: auto;
    margin-right: auto;
    max-width: 1234px;
    padding-bottom: 100px;
    padding-top: 40px;
    width: 100%
}

.activity-section .content-block .info-block {
    background-color: #fff5e5;
    border-radius: 16px;
    padding: 33px 30px
}

.activity-section .content-block .info-block .title {
    color: #321e14;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2
}

.activity-section .content-block .nav-tabs .nav-link {
    background-color: transparent;
    border-color: transparent;
    color: hsla(0,0%,9%,.7);
    font-size: 22px;
    font-weight: 500;
    margin-top: -.5px;
    padding: 4px 0 8px
}

.activity-section .content-block .nav-tabs .nav-link.active {
    border-bottom-color: #f0a77e;
    color: #f0a77e
}

.activity-section .content-block .tab-content .tab-body {
    padding-top: 14px
}

.activity-section .content-block .tab-content .tab-body .lbl {
    color: #181818;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 6px
}

.activity-section .content-block .tab-content .tab-body .sub-lbl {
    color: #321e14;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px
}

.activity-section .content-block .tab-content .tab-body .text {
    color: #321e14;
    font-size: 18px
}

.activity-section .content-block .tab-content .tab-body .info-text {
    color: #321e14;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 6px
}

.activity-section .content-block .tab-content .tab-body .info-text span {
    color: #f68446
}

.activity-section .content-block .tab-content .tab-body .list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0
}

.activity-section .content-block .tab-content .tab-body .list .text {
    margin-bottom: 0
}

.activity-section .content-block .tab-content .tab-body .list li {
    padding-left: 20px;
    position: relative
}

.activity-section .content-block .tab-content .tab-body .list li:not(:last-child) {
    margin-bottom: 16px
}

.activity-section .content-block .tab-content .tab-body .list li:before {
    aspect-ratio: 1/1;
    background-color: #f68446;
    border-radius: 50%;
    content: "";
    left: 0;
    position: absolute;
    top: 7px;
    width: 12px
}

.activity-section .content-block .payment-block .banner .img-container {
    isolation: isolate;
    margin-bottom: 19px;
    position: relative;
    text-align: center
}

.activity-section .content-block .payment-block .banner .img-container img {
    border-radius: 16px;
    max-height: 240px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

.activity-section .content-block .payment-block .banner .img-container .discount-block {
    aspect-ratio: 1/1;
    background-color: #1baa1b;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 25px;
    font-weight: 600;
    left: 19px;
    place-content: center;
    place-items: center;
    position: absolute;
    top: 9px;
    width: 80px;
    z-index: 99
}

.activity-section .content-block .payment-block .booking-card {
    background-color: rgba(246,132,70,.12);
    border-radius: 16px
}

.activity-section .content-block .payment-block .booking-card .list-group-item {
    background-color: transparent;
    border-color: transparent transparent #b9b9b9;
    padding: 8px 18px
}

.activity-section .content-block .payment-block .booking-card .list-group-item:last-child {
    border-bottom: 0
}

.activity-section .content-block .payment-block .booking-card .info {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: space-between
}

.activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
    color: #321e14;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.33
}

.activity-section .content-block .payment-block .booking-card .info .price-block {
    display: inline-flex;
    gap: 14px
}

.activity-section .content-block .payment-block .booking-card .info .price.discount {
    text-decoration: line-through;
    text-decoration-color: #dc3545;
}

.activity-section .content-block .payment-block .booking-card .info .input-block {
    max-width: 146px;
    width: 100%
}

.activity-section .content-block .payment-block .booking-card .info .form-control {
    border: 1px solid #707070;
    border-radius: 2px;
    font-size: 14px;
    opacity: .67;
    padding: 10px 9px
}

.activity-section .content-block .payment-block .booking-card .info .form-control::-moz-placeholder {
    color: rgba(0,0,0,.3)
}

.activity-section .content-block .payment-block .booking-card .info .form-control::placeholder {
    color: rgba(0,0,0,.3)
}

.activity-section .content-block .payment-block .booking-card .info .input-group .form-control {
    color: #000;
    font-size: 20px;
    padding: 6px;
    text-align: center
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn {
    border-color: hsla(0,0%,44%,.67)
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn:first-child {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn:hover svg g, .activity-section .content-block .payment-block .booking-card .info .input-group .btn:hover svg rect {
    opacity: 1
}

.activity-section .content-block .payment-block .booking-card .btn-container {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 13px;
    padding-top: 13px
}

.activity-section .content-block .payment-block .booking-card .btn-container .btn {
    border-radius: 3px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 12px;
    padding-top: 12px;
    text-transform: uppercase;
    white-space: nowrap
}

.activity-section .content-block .payment-block .booking-card .btn-container .btn-som {
    background-color: #b41d6e
}

.activity-section .content-block .payment-block .booking-card .btn-container .btn-som:hover {
    background-color: #9a195e
}

.activity-section .content-block .payment-block .booking-card .form-check [type=checkbox] {
    border-color: #aeaeae;
    border-radius: 1px
}

.activity-section .content-block .payment-block .booking-card .form-check .form-check-label {
    color: rgba(0,0,0,.7);
    font-size: 14px
}

.activity-section .content-block .payment-block .booking-card .form-check .form-check-label a {
    color: rgba(1,41,201,.7)
}

.activity-section .content-block .payment-block .booking-card .payment-getway {
    padding-top: 17px
}

.activity-section .content-block .payment-block .booking-card .payment-getway .img-container {
    align-items: center;
    display: flex;
    gap: 8px
}

.activity-section .content-block .payment-block .booking-card .payment-getway .img-container img {
    max-width: 35px
}

#confetti {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: -1
}

@media (max-width: 992px) {
    .activity-section .content-block {
        grid-template-columns: 1.2fr 1fr
    }

    .activity-section .content-block .info-block {
        padding: 35px 32px
    }

    .activity-section .content-block .info-block .title {
        font-size: 28px
    }

    .activity-section .content-block .nav-tabs .nav-link {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .lbl {
        font-size: 20px
    }

    .activity-section .content-block .tab-content .tab-body .sub-lbl {
        font-size: 17px
    }

    .activity-section .content-block .tab-content .tab-body .info-text, .activity-section .content-block .tab-content .tab-body .text {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .list li:before {
        top: 6px;
        width: 10px
    }

    .activity-section .content-block .payment-block .banner .img-container img {
        border-radius: 8px
    }

    .activity-section .content-block .payment-block .banner .img-container .discount-block {
        font-size: 20px;
        width: 70px
    }

    .activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
        font-size: 18px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-block {
        max-width: 140px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .form-control {
        font-size: 18px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .btn svg {
        width: 17px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container .btn {
        font-size: 16px
    }
}

@media (max-width: 767px) {
    .activity-section .content-block {
        gap: 40px;
        grid-template-columns: 1fr;
        padding-bottom: 70px;
        padding-top: 65px
    }

    .activity-section .content-block .info-block {
        padding: 28px 20px
    }

    .activity-section .content-block .info-block .title {
        font-size: 24px
    }

    .activity-section .content-block .nav-tabs .nav-link {
        font-size: 15px
    }

    .activity-section .content-block .tab-content .tab-body .lbl {
        font-size: 18px
    }

    .activity-section .content-block .tab-content .tab-body .sub-lbl {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .info-text, .activity-section .content-block .tab-content .tab-body .text {
        font-size: 14px
    }

    .activity-section .content-block .tab-content .tab-body .list li {
        padding-left: 16px
    }

    .activity-section .content-block .tab-content .tab-body .list li:before {
        width: 8px
    }

    .activity-section .content-block .payment-block .banner .img-container .discount-block {
        font-size: 16px;
        width: 60px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .form-control, .activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
        font-size: 16px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container .btn {
        font-size: 15px
    }
}

@media (max-width: 575px) {

    .payment-block {
        max-width: unset !important;
    }

    .hero-section .content-container .cards-content .cards-container .card-block .img-container img {
        height: 104px;
    }

    .swiper-backface-hidden .swiper-slide {
        width: 100% !important;
    }

    .activity-section .content-block {
        gap: 15px;
        grid-template-columns: 1fr;
        padding-bottom: 70px;
        padding-top: 20px
    }

    .activity-section .content-block .info-block {
        padding: 12px 10px;
        width: 99.5% !important;
    }

    .activity-section .content-block .info-block .title {
        font-size: 16px;
        margin-bottom: 4px;
    }

    .activity-section .content-block .nav-tabs .nav-link {
        font-size: 12px;
        padding-bottom: 6px
    }

    .activity-section .content-block .tab-content .tab-body {
        padding-top: 8px
    }

    .activity-section .content-block .tab-content .tab-body .lbl {
        font-size: 14px
    }

    .activity-section .content-block .tab-content .tab-body .sub-lbl {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .info-text, .activity-section .content-block .tab-content .tab-body .text {
        font-size: 12px;
        margin-bottom: 5px
    }

    .activity-section .content-block .tab-content .tab-body .list li {
        padding-left: 16px
    }

    .activity-section .content-block .tab-content .tab-body .list li:not(:last-child) {
        margin-bottom: 6px
    }

    .activity-section .content-block .tab-content .tab-body .list li:before {
        width: 8px
    }

    .activity-section .content-block .payment-block .banner .img-container {
        margin-bottom: 12px
    }

    .activity-section .content-block .payment-block .banner .img-container .discount-block {
        font-size: 14px;
        width: 50px
    }

    .activity-section .content-block .payment-block .banner .img-container img {
        max-height: 170px
    }

    .activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
        font-size: 14px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .form-control {
        font-size: 12px;
        padding-bottom: 4px;
        padding-top: 4px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .btn {
        padding: 3px 6px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-block {
        max-width: 125px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container {
        padding-bottom: 8px;
        padding-top: 8px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container .btn {
        font-size: 14px;
        padding-bottom: 6px;
        padding-top: 6px
    }

    .activity-section .content-block .payment-block .booking-card .payment-getway {
        padding-top: 12px
    }
}

.confirmation-section {
    background: transparent linear-gradient(121deg,rgba(254,219,79,.122),rgba(255,22,0,.188) 100%,#f25042 0) 0 0 no-repeat padding-box;
    min-height: 70vh;
    width: 100%
}

.confirmation-section .content-block {
    margin-left: auto;
    margin-right: auto;
    max-width: 958px;
    padding-bottom: 100px;
    padding-top: 60px;
    width: 100%
}

.confirmation-section .content-block .title {
    color: #b41d6e;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.325;
    margin-bottom: 5px
}

.confirmation-section .content-block .sub-text {
    color: #321e14;
    font-size: 16px
}

.confirmation-section .content-block .sub-text, .confirmation-section .content-block .title {
    text-align: center
}

.confirmation-section .confirmation-card {
    align-items: center;
    background-color: #fed4c6;
    border-radius: 16px;
    display: grid;
    gap: 28px;
    grid-template-columns: 340px 1fr;
    margin-bottom: 36px;
    margin-top: 42px;
    width: 100%
}

.confirmation-section .confirmation-card .banner, .confirmation-section .confirmation-card .banner .img-container, .confirmation-section .confirmation-card .banner img {
    height: 100%;
    width: 100%
}

.confirmation-section .confirmation-card .banner img {
    border-radius: 16px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.confirmation-section .confirmation-card .card-body {
    padding-bottom: 32px;
    padding-right: 60px;
    padding-top: 30px
}

.confirmation-section .confirmation-card .info-list {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.confirmation-section .confirmation-card .info-list .info-item {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-template-columns: 24px 1fr;
    margin-bottom: 12px
}

.confirmation-section .confirmation-card .info-list .info-item .icon {
    display: inline-block;
    width: 100%
}

.confirmation-section .confirmation-card .info-list .info-item .text {
    color: #321e14;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 0
}

.confirmation-section .confirmation-card .info-list hr {
    border-color: #f68446;
    border-top-width: 2px;
    opacity: 1
}

.confirmation-section .offer-info {
    background-color: #fff4ed;
    border-radius: 16px;
    color: #b41d6e;
    font-size: 28px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    padding: 20px;
    text-align: center;
    width: 100%
}

.confirmation-section .social-list {
    align-items: center;
    display: flex;
    gap: 22px;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding-bottom: 16px;
    padding-left: 0;
    padding-top: 24px
}

.confirmation-section .social-list a {
    display: inline-block
}

@media (max-width: 992px) {
    .confirmation-section .content-block .title {
        font-size: 30px
    }

    .confirmation-section .confirmation-card .info-list .info-item .text {
        font-size: 20px
    }

    .confirmation-section .offer-info {
        font-size: 22px
    }
}

@media (max-width: 780px) {
    .confirmation-section .confirmation-card {
        grid-template-columns: 1fr 1.45fr
    }

    .confirmation-section .confirmation-card .card-body {
        padding-right: 30px
    }
}

@media (max-width: 767px) {
    .confirmation-section .confirmation-card {
        grid-template-columns: 1fr
    }

    .confirmation-section .confirmation-card .card-body {
        padding-left: 30px;
        padding-top: 10px
    }

    .confirmation-section .confirmation-card .banner {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        text-align: center
    }

    .confirmation-section .confirmation-card .banner .img-container, .confirmation-section .confirmation-card .banner img {
        height: auto
    }
}

@media (max-width: 575px) {
    .confirmation-section .content-block {
        padding-bottom: 50px;
        padding-top: 20px
    }

    .confirmation-section .content-block .title {
        font-size: 20px
    }

    .confirmation-section .content-block .sub-text {
        display: none;
        font-size: 14px
    }

    .confirmation-section .confirmation-card {
        gap: 14px;
        margin-bottom: 15px;
        margin-top: 10px
    }

    .confirmation-section .confirmation-card .banner {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px
    }

    .confirmation-section .confirmation-card .banner img {
        max-height: 160px
    }

    .confirmation-section .confirmation-card .card-body {
        padding: 0 10px 5px
    }

    .confirmation-section .confirmation-card .info-list .info-item {
        margin-bottom: 6px
    }

    .confirmation-section .confirmation-card .info-list .info-item .text {
        font-size: 15px
    }

    .confirmation-section .confirmation-card .info-list hr {
        margin: 8px 0
    }

    .confirmation-section .offer-info {
        border-radius: 10px;
        font-size: 14px;
        padding: 10px 8px
    }

    .confirmation-section .social-list {
        padding-top: 16px
    }

    .confirmation-section .social-list svg {
        height: auto;
        width: 35px
    }
}


.noborder {
    border: none;
    background-color: transparent;
}

.swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    height: 100%;
    position: relative;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    width: 100%;
    z-index: 1
}

.swiper-android .swiper-slide, .swiper-ios .swiper-slide, .swiper-wrapper {
    transform: translateZ(0)
}

.swiper-horizontal {
    touch-action: pan-y
}

.swiper-vertical {
    touch-action: pan-x
}

.swiper-slide {
    display: block;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    width: 100%
}

.swiper-slide-invisible-blank {
    visibility: hidden
}

.swiper-autoheight, .swiper-autoheight .swiper-slide {
    height: auto
}

.swiper-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform,height
}

.swiper-backface-hidden .swiper-slide {
    backface-visibility: hidden;
    transform: translateZ(0)
}

.swiper-3d.swiper-css-mode .swiper-wrapper {
    perspective: 1200px
}

.swiper-3d .swiper-wrapper {
    transform-style: preserve-3d
}

.swiper-3d {
    perspective: 1200px
}

.swiper-3d .swiper-cube-shadow, .swiper-3d .swiper-slide {
    transform-style: preserve-3d
}

.swiper-css-mode > .swiper-wrapper {
    -ms-overflow-style: none;
    overflow: auto;
    scrollbar-width: none
}

.swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none
}

.swiper-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start
}

.swiper-css-mode.swiper-horizontal > .swiper-wrapper {
    scroll-snap-type: x mandatory
}

.swiper-css-mode.swiper-vertical > .swiper-wrapper {
    scroll-snap-type: y mandatory
}

.swiper-css-mode.swiper-free-mode > .swiper-wrapper {
    scroll-snap-type: none
}

.swiper-css-mode.swiper-free-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: none
}

.swiper-css-mode.swiper-centered > .swiper-wrapper:before {
    content: "";
    flex-shrink: 0;
    order: 9999
}

.swiper-css-mode.swiper-centered > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: center center;
    scroll-snap-stop: always
}

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
    margin-inline-start: var(--swiper-centered-offset-before)
}

.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper:before {
    height: 100%;
    min-height: 1px;
    width: var(--swiper-centered-offset-after)
}

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
    margin-block-start: var(--swiper-centered-offset-before)
}

.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper:before {
    height: var(--swiper-centered-offset-after);
    min-width: 1px;
    width: 100%
}

.swiper-3d .swiper-slide-shadow, .swiper-3d .swiper-slide-shadow-bottom, .swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right, .swiper-3d .swiper-slide-shadow-top {
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10
}

.swiper-3d .swiper-slide-shadow {
    background: rgba(0,0,0,.15)
}

.swiper-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(270deg,rgba(0,0,0,.5),transparent)
}

.swiper-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(90deg,rgba(0,0,0,.5),transparent)
}

.swiper-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(0deg,rgba(0,0,0,.5),transparent)
}

.swiper-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(180deg,rgba(0,0,0,.5),transparent)
}

.swiper-lazy-preloader {
    border: 4px solid var(--swiper-preloader-color,var(--swiper-theme-color));
    border-radius: 50%;
    border-top: 4px solid transparent;
    box-sizing: border-box;
    height: 42px;
    left: 50%;
    margin-left: -21px;
    margin-top: -21px;
    position: absolute;
    top: 50%;
    transform-origin: 50%;
    width: 42px;
    z-index: 10
}

.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader, .swiper:not(.swiper-watch-progress) .swiper-lazy-preloader {
    animation: swiper-preloader-spin 1s linear infinite
}

.swiper-lazy-preloader-white {
    --swiper-preloader-color: #fff
}

.swiper-lazy-preloader-black {
    --swiper-preloader-color: #000
}

@keyframes swiper-preloader-spin {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

.swiper-virtual .swiper-slide {
    -webkit-backface-visibility: hidden;
    transform: translateZ(0)
}

.swiper-virtual.swiper-css-mode .swiper-wrapper:after {
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0
}

.swiper-virtual.swiper-css-mode.swiper-horizontal .swiper-wrapper:after {
    height: 1px;
    width: var(--swiper-virtual-size)
}

.swiper-virtual.swiper-css-mode.swiper-vertical .swiper-wrapper:after {
    height: var(--swiper-virtual-size);
    width: 1px
}

:root {
    --swiper-navigation-size: 44px
}

.swiper-button-next, .swiper-button-prev {
    align-items: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
    cursor: pointer;
    display: flex;
    height: var(--swiper-navigation-size);
    justify-content: center;
    margin-top: calc(0px - var(--swiper-navigation-size)/2);
    position: absolute;
    top: var(--swiper-navigation-top-offset,50%);
    width: calc(var(--swiper-navigation-size)/44*27);
    z-index: 10
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    cursor: auto;
    opacity: .35;
    pointer-events: none
}

.swiper-button-next.swiper-button-hidden, .swiper-button-prev.swiper-button-hidden {
    cursor: auto;
    opacity: 0;
    pointer-events: none
}

.swiper-navigation-disabled .swiper-button-next, .swiper-navigation-disabled .swiper-button-prev {
    display: none !important
}

.swiper-button-next svg, .swiper-button-prev svg {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    transform-origin: center;
    width: 100%
}

.swiper-rtl .swiper-button-next svg, .swiper-rtl .swiper-button-prev svg {
    transform: rotate(180deg)
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset,10px);
    right: auto
}

.swiper-button-lock {
    display: none
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    font-variant: normal;
    letter-spacing: 0;
    line-height: 1;
    text-transform: none !important
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: "prev"
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    left: auto;
    right: var(--swiper-navigation-sides-offset,10px)
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: "next"
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    transform: translateZ(0);
    transition: opacity .3s;
    z-index: 10
}

.swiper-pagination.swiper-pagination-hidden {
    opacity: 0
}

.swiper-pagination-disabled > .swiper-pagination, .swiper-pagination.swiper-pagination-disabled {
    display: none !important
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: var(--swiper-pagination-bottom,8px);
    left: 0;
    top: var(--swiper-pagination-top,auto);
    width: 100%
}

.swiper-pagination-bullets-dynamic {
    font-size: 0;
    overflow: hidden
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    position: relative;
    transform: scale(.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(.33)
}

.swiper-pagination-bullet {
    background: var(--swiper-pagination-bullet-inactive-color,#000);
    border-radius: var(--swiper-pagination-bullet-border-radius,50%);
    display: inline-block;
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px));
    opacity: var(--swiper-pagination-bullet-inactive-opacity,.2);
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px))
}

button.swiper-pagination-bullet {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer
}

.swiper-pagination-bullet:only-child {
    display: none !important
}

.swiper-pagination-bullet-active {
    background: var(--swiper-pagination-color,var(--swiper-theme-color));
    opacity: var(--swiper-pagination-bullet-opacity,1)
}

.swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical > .swiper-pagination-bullets {
    left: var(--swiper-pagination-left,auto);
    right: var(--swiper-pagination-right,8px);
    top: 50%;
    transform: translate3d(0,-50%,0)
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    display: block;
    margin: var(--swiper-pagination-bullet-vertical-gap,6px) 0
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: transform .2s,top .2s
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap,4px)
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: transform .2s,left .2s
}

.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: transform .2s,right .2s
}

.swiper-pagination-fraction {
    color: var(--swiper-pagination-fraction-color,inherit)
}

.swiper-pagination-progressbar {
    background: var(--swiper-pagination-progressbar-bg-color,rgba(0,0,0,.25));
    position: absolute
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color,var(--swiper-theme-color));
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transform-origin: left top;
    width: 100%
}

.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top
}

.swiper-horizontal > .swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal, .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite, .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    height: var(--swiper-pagination-progressbar-size,4px);
    left: 0;
    top: 0;
    width: 100%
}

.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-vertical, .swiper-vertical > .swiper-pagination-progressbar {
    height: 100%;
    left: 0;
    top: 0;
    width: var(--swiper-pagination-progressbar-size,4px)
}

.swiper-pagination-lock {
    display: none
}

.swiper-scrollbar {
    background: var(--swiper-scrollbar-bg-color,rgba(0,0,0,.1));
    border-radius: var(--swiper-scrollbar-border-radius,10px);
    position: relative;
    -ms-touch-action: none
}

.swiper-scrollbar-disabled > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-disabled {
    display: none !important
}

.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
    bottom: var(--swiper-scrollbar-bottom,4px);
    height: var(--swiper-scrollbar-size,4px);
    left: var(--swiper-scrollbar-sides-offset,1%);
    position: absolute;
    top: var(--swiper-scrollbar-top,auto);
    width: calc(100% - var(--swiper-scrollbar-sides-offset, 1%)*2);
    z-index: 50
}

.swiper-scrollbar.swiper-scrollbar-vertical, .swiper-vertical > .swiper-scrollbar {
    height: calc(100% - var(--swiper-scrollbar-sides-offset, 1%)*2);
    left: var(--swiper-scrollbar-left,auto);
    position: absolute;
    right: var(--swiper-scrollbar-right,4px);
    top: var(--swiper-scrollbar-sides-offset,1%);
    width: var(--swiper-scrollbar-size,4px);
    z-index: 50
}

.swiper-scrollbar-drag {
    background: var(--swiper-scrollbar-drag-bg-color,rgba(0,0,0,.5));
    border-radius: var(--swiper-scrollbar-border-radius,10px);
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%
}

.swiper-scrollbar-cursor-drag {
    cursor: move
}

.swiper-scrollbar-lock {
    display: none
}

.swiper-zoom-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%
}

.swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
    max-height: 100%;
    max-width: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.swiper-slide-zoomed {
    cursor: move;
    touch-action: none
}

.swiper .swiper-notification {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: -1000
}

.swiper-free-mode > .swiper-wrapper {
    margin: 0 auto;
    transition-timing-function: ease-out
}

.swiper-grid > .swiper-wrapper {
    flex-wrap: wrap
}

.swiper-grid-column > .swiper-wrapper {
    flex-direction: column;
    flex-wrap: wrap
}

.swiper-fade.swiper-free-mode .swiper-slide {
    transition-timing-function: ease-out
}

.swiper-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity
}

.swiper-fade .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-fade .swiper-slide-active, .swiper-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-cube {
    overflow: visible
}

.swiper-cube .swiper-slide {
    backface-visibility: hidden;
    height: 100%;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    width: 100%;
    z-index: 1
}

.swiper-cube .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-cube.swiper-rtl .swiper-slide {
    transform-origin: 100% 0
}

.swiper-cube .swiper-slide-active, .swiper-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-cube .swiper-slide-active, .swiper-cube .swiper-slide-next, .swiper-cube .swiper-slide-prev {
    pointer-events: auto;
    visibility: visible
}

.swiper-cube .swiper-cube-shadow {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: .6;
    position: absolute;
    width: 100%;
    z-index: 0
}

.swiper-cube .swiper-cube-shadow:before {
    background: #000;
    bottom: 0;
    content: "";
    filter: blur(50px);
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.swiper-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible
}

.swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-bottom, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-left, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-right, .swiper-cube .swiper-slide-shadow-cube.swiper-slide-shadow-top {
    backface-visibility: hidden;
    z-index: 0
}

.swiper-flip {
    overflow: visible
}

.swiper-flip .swiper-slide {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1
}

.swiper-flip .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-flip .swiper-slide-active, .swiper-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-bottom, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-left, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-right, .swiper-flip .swiper-slide-shadow-flip.swiper-slide-shadow-top {
    backface-visibility: hidden;
    z-index: 0
}

.swiper-creative .swiper-slide {
    backface-visibility: hidden;
    overflow: hidden;
    transition-property: transform,opacity,height
}

.swiper-cards {
    overflow: visible
}

.swiper-cards .swiper-slide {
    backface-visibility: hidden;
    overflow: hidden;
    transform-origin: center bottom
}

.description {
    padding: 2px !important;
}



.swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    height: 100%;
    position: relative;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    width: 100%;
    z-index: 1;
}



@media (min-width: 768px) {
    .swiper-slide {
        width: 330.667px; /* Adjust as needed for larger screens */
    }
}

/* Define styles for smaller screens */
@media (max-width: 767px) {
    .swiper-slide {
        width: 100%; /* Full width for smaller screens */
        margin-right: 0; /* Remove right margin for smaller screens */
    }
}



.cards-content .description {
    height: auto !important;
}



.fn-container .img-container img {
    height: 188px !important;

}

.swiper-wrapper img {
    height: 200px !important;
}
