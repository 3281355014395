@import '../../common-style/variable.scss';


.Dashboard_Sidebar {
    position: relative;
    width: 280px;
    height: 100vh;
    background-color: $Primary-Light2;
    border-right: 1px solid $Black-10;

    @media (max-width: 992px) {
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 999;
        width: 100%;
        background-color: $White;
        transition: .3s;
    }

    .Dashboard_Sidebar_inr {
        @media (max-width: 992px) {
            position: relative;
            left: -280px;
            background-color: $Primary-Light2;
            max-width: 280px;
            width: 100%;
            height: 100vh;
            transition: .3s;
            overflow-y: auto;
        }
    }

    .sidebar_close_icon {
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 300px;
        top: 20px;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: $White;
        border-radius: 4px;
        transition: .3s;

        @media (max-width: 575px) {
            width: 32px;
            height: 32px;
            top: 12px;
            left: 232px;
            z-index: 2;
        }

        &:hover {
            background-color: $Primary;

            .close_icon {
                color: $White;
            }
        }

        .close_icon {
            font-size: 20px;
            line-height: 20px;
            color: $Black-65;
            transition: .3s;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 16px;
            }
        }
    }

    .logo_otr {
        display: flex;
        border-bottom: 1px solid $Black-10;
        padding: 20px 24px;
    }

    .logo_icon {
        display: none;
        width: 40px;
        height: 40px;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
    }

    .brand_logo {
        height: 40px;
        width: 220px;
        object-fit: contain;
        object-position: left;
    }

    .sidebar_menu_main {
        height: calc(100% - 82px);
        overflow-y: auto;
    }

    .sidebar_menu_ul {
        padding: 24px;
    }

    .sidebar_menu_link {
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid $Black-10;
        padding: 12px 0;
        transition: .3s;

        &:hover {
            border-bottom: 1px solid $Primary;

            .menu_icon {
                color: $Primary;
            }

            .menu_text {
                color: $Primary;
            }
        }
    }

    .menu_icon {
        font-size: 20px;
        line-height: 20px;
        color: $Black-65;
        transition: .3s;
    }

    .menu_text {
        font-family: $font-family-Medium;
        color: $Black-65;
        transition: .3s;
    }

    .active {
        border-bottom: 1px solid $Primary;

        .menu_icon {
            color: $Primary;
        }

        .menu_text {
            color: $Primary;
        }
    }
}

.Dashboard_Sidebar_strech {
    width: 72px;

    @media (max-width: 992px) {
        width: 280px;
        left: 0;
        transition: .3s;

        .Dashboard_Sidebar_inr {
            left: 0;
            transition: .3s;
        }
    }

    .sidebar_close_icon {
        @media (max-width: 992px) {
            display: flex;
        }
    }

    .logo_otr {
        padding: 20px 16px;

        @media (max-width: 575px) {
            padding: 12px 16px;
        }
    }

    .logo_icon {
        display: flex;

        @media (max-width: 992px) {
            display: none;
        }
    }

    .brand_logo {
        display: none;

        @media (max-width: 992px) {
            display: flex;
        }

        @media (max-width: 575px) {
            height: 32px;
            width: 174px;
        }
    }

    .menu_text {
        display: none;

        @media (max-width: 992px) {
            display: flex;
        }

        @media (max-width: 575px) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .sidebar_menu_ul {
        padding: 16px 0;

        @media (max-width: 992px) {
            padding: 16px;
        }
    }

    .sidebar_menu_link {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 992px) {
            justify-content: flex-start;
        }
    }

    .menu_icon {
        font-size: 24px;
        line-height: 24px;

        @media (max-width: 575px) {
            font-size: 16px;
            line-height: 16px;
        }
    }

    .sidebar_menu_link {
        padding: 16px 0;

        @media (max-width: 575px) {
            padding: 8px 0;
        }
    }
}