@import '../../common-style/variable.scss';

.cross-icon {
    display: flex;
    flex-direction: row-reverse;
}

.login_popup {
    height: 100vh;

    .input{
        font-size: 16px;
    line-height: 24px;
    font-family: "Inter-Medium";
    padding: 14px 24px;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 8px;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
    }
    .buy_as_guest{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .button_kjop{
        margin-top: 24px;
    }

    .login_main {
        padding: 0 !important;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        height: 100%;
    }

    .container {
        height: 100%;

        @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            padding: 0 !important;
        }
    }
    #Layer_1{
        cursor: pointer;
    }
    .row_custom {
        justify-content: center;
        align-items: center;
        height: calc(100% - 80px);
        margin: 40px 0;
        overflow-y: auto;
    }

    .col_form_inr {
        background-color: $Primary-Light2;
        padding: 26px 30px 30px 40px;
        border-radius: 16px;

        @media (max-width: 575px) {
            padding: 16px;
        }
    }

    .form_heading {
        color: $Black;
        margin-bottom: 24px;
        text-align: center;
    }

    .form_main {
        margin: 0;
    }

    .Input_otr {
        margin-bottom: 24px;
    }

    .action_otr {
        display: flex;
    }

    .form_btn {
        width: 100%;
    }

    .check_forget_otr {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .forgot_text {
        font-family: $font-family-Medium;
        color: $Black;
    }

    .heading_another {
        color: $Black;
        text-align: center;
    }

    .google_facebok_otr {
        display: flex;
        align-items: center;
        gap: 24px;

        .buttons_otr {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 50%;
            background-color: $White;
            border: 1px solid $Primary;
            padding: 14px 16px;
            border-radius: 8px;
            color: $Primary;
            transition: .3s;

            @media (max-width: 575px) {
                width: 100%;
            }

            &:hover {
                background-color: $Primary;
                color: $White;
            }

            .button_icon {
                font-size: 24px;
                line-height: 24px;
            }

            .button_text {
                line-height: 24px;
            }
        }
    }
}