@import '../../common-style/variable.scss';

.dashboard_header {
    position: relative;
    padding: 20px 24px;
    z-index: 9999;

    .wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .sidebar_menu_icon_otr {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: $Black-3;
        border-radius: 4px;
        cursor: pointer;
        transition: .3s;

        &:hover {
            background-color: $Primary;

            .sidebar_menu_icon {
                color: $White;
            }
        }
    }

    .sidebar_menu_icon {
        font-size: 20px;
        line-height: 20px;
        color: $Black-65;
        transition: .3s;
    }

    .search_otr {
        position: relative;
        border-left: 1px solid $Black-10;
        padding-left: 24px;
        flex: 1;
    }

    .search_icon {
        position: absolute;
        font-size: 20px;
        line-height: 20px;
        color: $Black-40;
        top: 50%;
        left: 24px;
        transform: translateY(-50%);
    }

    .search_input {
        padding: 8px 16px 8px 36px;
        background-color: transparent;
        box-shadow: none;
    }

    .profile_main {
        position: relative;
        border-left: 1px solid $Black-10;
        padding-left: 24px;

        &:hover {
            .profile_drop_otr {
                opacity: 1;
                visibility: visible;
                top: 40px;
            }
        }

        .profile_otr {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
        }

        .profile_img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            object-position: center;
            border-radius: 100%;
        }

        .user_name {
            color: $Black-40;
        }

        .profile_drop_otr {
            position: absolute;
            top: 100px;
            right: 0;
            padding-top: 20px;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
        }

        .profile_drop_ul {
            width: 200px;
            background-color: $White;
            box-shadow: $Box-Shadow-Custom;
            border-radius: 4px;
            padding: 16px 20px 20px 20px;
        }

        .user_text {
            color: $Black-65;
        }

        .profile_drop_link {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px 0;
            border-bottom: 1px solid $Black-10;
            transition: .3s;

            &:hover {
                border-bottom: 1px solid $Primary;

                .menu_icon {
                    color: $Primary;
                }

                .menu_text {
                    color: $Primary;
                }
            }
        }

        .menu_icon {
            font-size: 16px;
            line-height: 16px;
            color: $Black-65;
            transition: .3s;
        }

        .menu_text {
            font-family: $font-family-Medium;
            color: $Black-65;
            transition: .3s;
        }
    }
}

.custom_header {
    padding: 0 !important;
}

.circle {
    display: inline-block;
    background-color: orangered;
    border-radius: 50%;
}

.circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 38px;
    width: 38px;
    font-size: 25px;
    background-color: #fc9765;
    border-radius: 50%;

}