@font-face {
    font-family: 'Inter-Regular';
    src: url('./Inter-Regular.eot');
    src: url('./Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Inter-Regular.woff2') format('woff2'),
        url('./Inter-Regular.woff') format('woff'),
        url('./Inter-Regular.ttf') format('truetype'),
        url('./Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('./Inter-Bold.eot');
    src: url('./Inter-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Inter-Bold.woff2') format('woff2'),
        url('./Inter-Bold.woff') format('woff'),
        url('./Inter-Bold.ttf') format('truetype'),
        url('./Inter-Bold.svg#Inter-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('./Inter-SemiBold.eot');
    src: url('./Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Inter-SemiBold.woff2') format('woff2'),
        url('./Inter-SemiBold.woff') format('woff'),
        url('./Inter-SemiBold.ttf') format('truetype'),
        url('./Inter-SemiBold.svg#Inter-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('./Inter-Medium.eot');
    src: url('./Inter-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Inter-Medium.woff2') format('woff2'),
        url('./Inter-Medium.woff') format('woff'),
        url('./Inter-Medium.ttf') format('truetype'),
        url('./Inter-Medium.svg#Inter-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}