@import "../../common-style//variable.scss";


.table-row:hover
{
cursor: pointer;
background-color: #f9c6a7b7;
}
.radio-group
{
   
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0;
}
.radio
{
  display: flex;
  gap: 5px;
}

.my_page_main {
  .partner_page_main {
    margin: 112px 0 128px 0;

    .heading_filter_otr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .heading_activity {
        color: $Black;
      }

      .filter_search_otr {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      .filter_main {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      .sort_text {
        color: $Black-65;
      }

      .form-select {
        flex: 1;
      }

      .search_otr {
        position: relative;

        .search_icon {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 20px;
          line-height: 20px;
          color: $Black-65;
        }

        .search_input {
          padding-left: 46px;
          width: 250px;
        }
      }
    }

    .partner_table {
      max-height: 492px;
      overflow-y: auto;

      .data_table {
        width: 100%;
      }

      .table_header {
        background-color: $Grey-Bg;
        border-bottom: 1px solid $Black-25;
        position: sticky;
        top: 0;
        left: 0;

        tr {
          th {
            padding: 8px 0;

            &:first-child {
              width: 50px;
            }

            &:nth-child(2) {
              width: 140px;
            }

            &:nth-child(3) {
              width: 100px;
            }

            &:nth-child(4) {
              width: 30px;
            }

            &:nth-child(5),
            &:nth-child(6),
            &:last-child {
              width: 100px;
            }

            &:not(:last-child) {
              .header_text {
                border-right: 1px solid $Black-25;
              }
            }
          }

          .header_text {
            color: $Black;
            padding: 0 10px 0 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }

      .table_body {
        tr {
          height: 72px;
          border-bottom: 1px solid $Black-25;
          padding: 8px 0;

          td {
            &:first-child {
              .body_text {
                color: $Black;
              }
            }

            &:not(:last-child) {
              .body_text {
                border-right: 1px solid $Black-25;
              }
            }
          }

          .body_text {
            display: flex;
            align-items: center;
            color: $Black-65;
            padding: 5px 10px 5px 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .prodile_otr {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 10px 0 16px;
            border-right: 1px solid $Black-25;

            .profile_img {
              width: 36px;
              height: 36px;
              object-fit: cover;
              object-position: center;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}