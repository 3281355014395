@import '../../common-style/variable.scss';

.partnere{
  position: fixed;
  left: 0;
  top: 629px;
  z-index: 9999;
  width: 326px;
  text-transform: uppercase;
  background: #f5f5f5;
  font-weight: 700;
  padding-top: 42px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  flex-direction: row-reverse;
  align-items: center;
  padding-bottom: 44px;
  cursor: pointer;
  transform: translateX(-262px);
  transition: all 0.5s ease-in-out;
  opacity: 0.7;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.partnere .vare_patnere{
  height: 157px;
  transform: rotate(90deg);
  width: 565px;
  font-size: 20px;
  text-align: center;
  margin-right: -11px;
  line-height: 1;
}
.partere-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 94px;
  margin-right: -96px;
  margin-top: -28px;
}
.partere-image img {
  width: 128px;
  border-radius: 50%;
}
.extend_css{
  transform: translateX(-80px) !important;
  opacity: 1 !important;
}



