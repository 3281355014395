@import '../../common-style//variable.scss';


.sort_filter_container
{
    display: flex !important;
    align-items: center;
    gap: 1rem;
    width: 100%;
}
.sort_filter_container > select
{
   height : 52px !important;
}

.searchContainer
{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    
}

.order_page_main {
    .heading_filter_otr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .heading_activity {
            color: $Black;
        }

        .filter_main {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .sort_text {
            color: $Black-65;
        }

        .form-select {
            flex: 1;
        }
    }

    .order_table {
        max-height: 500px;
        overflow-y: auto;

        .data_table {
            width: 100%;
            min-width: 1100px;
        }

        .table_header {
            background-color: $Grey-Bg;
            border-bottom: 1px solid $Black-25;
            position: sticky;
            top: 0;
            left: 0;

            tr {

                th {
                    padding: 8px 0;

                    &:first-child {
                        width: 129px;                 
                       }

                    &:nth-child(3) {
                        width: 180px;
                    }

                    &:nth-child(4) {
                        width: 151px;
                    }

                    &:nth-child(5) {
                        width: 180px;
                    }

                    &:nth-child(6) {
                        width: 155px;
                    }

                    &:nth-child(7) {
                        width: 110px;
                    }

                    &:last-child {
                        width: 125px;
                    }

                    &:not(:last-child) {
                        .header_text {
                            border-right: 1px solid $Black-25;
                        }
                    }
                }

                .header_text {
                    color: $Black;
                    padding: 0 10px 0 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }

        .table_body {
            tr {
                height: 72px;
                border-bottom: 1px solid $Black-25;
                padding: 8px 0;

                td {
                    &:first-child {
                        .body_text {
                            color: $Black;
                        }
                    }

                    &:not(:last-child) {
                        .body_text {
                            border-right: 1px solid $Black-25;
                        }
                    }
                }

                .body_text {
                    color: $Black-65;
                    padding: 5px 10px 5px 16px;
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .profile_otr {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 0 10px 0 16px;
                    border-right: 1px solid $Black-25;

                    .profile_img {
                        width: 36px;
                        height: 36px;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 100%;
                    }

                    .profile_name {
                        display: -webkit-box;
                        color: $Black-65;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }

                .icon_otr {
                    display: flex;
                    align-items: center;
                    padding: 0 10px 0 16px;

                    .edit_icon {
                        font-size: 20px;
                        line-height: 20px;
                        color: $Black-65;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}