@import '../../common-style/variable.scss';

.campaign_img {
    width: 500px;
}

@media (max-width: 1360px) {
    .prisen_information {
        font-size: 20px !important;
    }

    .prisen_information p {
        font-size: 17px !important;
    }

    .icon_text_otr > div p, .icon_text_otr > span {
        font-size: 22px !important;
    }

    .campaign_img {
        width: 441px !important;
    }

    .campaign_Page .infoActivity .column_image {
        width: 439px !important;
        max-height: 276px !important;
        margin-top: 95px !important;
    }
}

@media (max-width: 1105px) {
    .campaign_Page .infoActivity > div {
        flex-wrap: wrap !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .campaign_Page .infoActivity div:nth-child(2) .column_activity {
        margin-top: 0px !important;
        width: 90% !important;
    }
}

@media (max-width: 793px) {
    .campaign_Page .infoActivity div:nth-child(1) .column_activity {
        width: 95% !important;
    }

    .campaign_Page .infoActivity .column_activity {
        margin: 13px !important;
    }
}

@media (max-width: 645px) {
    .campaign_Page .infoActivity div:nth-child(1) .column_activity:nth-child(1) {
        width: 462px !important;
    }

    .campaign_img {
        width: 423px !important;
    }

    .campaign_Page .infoActivity .column_image {
        width: 439px !important;
        max-height: 302px !important;
        margin-top: 44px !important;
    }

    .campaign_Page .infoActivity div:nth-child(2) .column_activity:nth-child(3) {
        display: flex !important;
        justify-content: center !important;
    }
}

@media (max-width: 577px) {
    .campaign_img {
        width: 100% !important;
    }

    .campaign_Page .infoActivity div:nth-child(1) .column_activity:nth-child(1) {
        width: 427px !important;
        max-height: 302px !important;
    }

    .campaign_Page .infoActivity .swiper-wrapper {
        width: 390px !important;
    }

    .swiper-fade .swiper-slide-active, .swiper-fade .swiper-slide-active .swiper-slide-active {
        width: 390px !important;
    }
}

@media (max-width: 345px) {
    .campaign_img {
        width: 213% !important;
    }

    .campaign_Page .infoActivity div:nth-child(1) .column_activity:nth-child(1) {
        width: 323px !important;
    }

    .campaign_img {
        width: 288px !important;
    }

    .swiper-fade .swiper-slide-active, .swiper-fade .swiper-slide-active .swiper-slide-active {
        width: 288px !important;
    }

    .campaign_Page .infoActivity .swiper-wrapper {
        width: 290px !important;
    }

    .Theme_btn_primary {
        padding: 18px 98px !important;
    }

    .campaign_Page .infoActivity div:nth-child(2) .column_activity {
        width: 80% !important;
    }
}

.discountSection {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
}

.iconInput {
    position: relative;
}

.iconInput img {
    position: absolute;
    top: 3px;
    right: 7px;
}

.discountSection input[type=text] {
    width: 176px;
    padding: 4px 6px;
    outline: none;
}

.discountSection button {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    font-family: "Inter-Bold";
    color: #FFFFFF;
    background-color: #FC9765;
    -webkit-backdrop-filter: blur(12.5px);
    backdrop-filter: blur(12.5px);
    border-radius: 8px;
    padding: 9px 28px;
    transition: 0.4s;
    cursor: pointer;
    border: none;
    outline: none;
    overflow: hidden;
}

.backdrop {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(8px);
}

.disabled {
    background-color: #cccccc !important;
    color: #666666 !important;
}

.campaign_Page {
    ul, li {
        margin: auto !important;
        list-style: outside !important;
    }

    li {
        padding: auto !important;
    }

    ul {
        padding-left: 2rem !important;
        margin-bottom: 1rem !important;
        margin-top: 0 !important;
    }

    .campaign_main {
        padding: 0px 0px 128px 0px !important;

        @media (max-width: 575px) {
            padding: 48px 0;
        }
    }

    .payments_options {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .payments_options > img {
        margin-left: 5px;
        width: 42px;
    }

    .infoActivity {
        width: 100%;

        .column_activity {
            width: 25%;
            margin: 30px;
            padding: 20px;
        }

        .icon_text_otr {
            align-items: center;
        }

        .icon_text_otr > div i, .icon_text_otr > div span {
            margin: 2px;
            font-size: 28px;
        }

        .icon_text_otr > div p {
            font-size: 28px;
        }

        .column_activity:nth-child(2) {
            width: 41%;
        }

        .vilkar {
            width: 594px;
            content: "";
        }

        .terms_and_con {
            cursor: pointer;
            color: #1878d2;
        }

        .wrap_rabat_with_text {
            display: inline-block;
        }

        .rabat_round {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            text-align: center;
            font-size: 35px;
            float: left;
            font-weight: bold;
            shape-outside: circle();
            background-color: Green;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
        }

        .column_image {
            width: 594px;
            max-height: 347px;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            padding: 19px 19px 30px 19px;
            /* height: 410px; */
            /* padding-right: 83px; */
            border-radius: 12px;
            margin-top: 100px;
        }

        .extend {
            width: 38px;
            cursor: pointer;
        }

        .swiper-wrapper {
            width: 500px;
        }

        .swiper-pagination {
            margin-top: -11px;
            z-index: 9999999999;
            position: relative;
        }

        @media (max-width: 992px) {
            padding-right: 0;
        }

        .campaign_text {
            color: $Black-65;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        .heading_terms {
            color: $Black;
            margin: 24px 0 12px 0;
        }

        .action_otr {
            display: inline-flex;
            margin-top: 40px;
        }
    }

    .img_otr img {
        aspect-ratio: 16/9;
        border-radius: 12px;
        /*box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;*/
    }

    .col_content_inr {
        padding-right: 48px;

        @media (max-width: 992px) {
            padding-right: 0;
        }

        .campaign_text {
            color: $Black-65;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        .heading_terms {
            color: $Black;
            margin: 24px 0 12px 0;
        }

        .action_otr {
            display: inline-flex;
            margin-top: 40px;
        }
    }

    .col_img_inr {
        .campaign_img {
            width: 100%;
            height: 500px;
            object-fit: fill;
            object-position: center;
            // animation-name: UpDown;
            // animation-duration: 6s;
            // animation-iteration-count: infinite;
            // animation-timing-function: linear;
        }

        .social_text_otr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $Primary-Light2;
            border-radius: 8px;
            padding: 28px 36px;
            margin-top: 72px;
        }

        .text_ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .text_heading {
            display: flex;
            align-items: center;
            color: $Black;

            span {
                font-family: $font-family-Medium;
                color: $Black-65;
                margin-left: 4px;
            }

            .icon_text_otr {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-right: 16px;
                font-size: 30px;
            }

            .icon_text_otr span {
                font-size: 30px !important;
            }

            .q_icon {
                font-size: 24px;
                line-height: 24px;
                color: $Primary;
                transition: .3s;
            }
        }

        .social_ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 120px;
            gap: 16px;
        }

        .social_a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $White;
            box-shadow: $Box-Shadow-Custom;
            transition: .3s;

            &:hover {
                background-color: $Primary;

                .social_icon {
                    color: $White;
                }
            }

            .social_icon {
                font-size: 24px;
                line-height: 24px;
                color: $Primary;
                transition: .3s;
            }
        }
    }
}

.activity-section {
    min-height: 673px;
}

.activity-section .content-block {
    display: grid;
    gap: 20px;
    grid-template-columns: 1.482fr 1fr;
    margin-left: auto;
    margin-right: auto;
    max-width: 1234px;
    padding-bottom: 100px;
    padding-top: 40px;
    width: 100%;
}

.activity-section .content-block .info-block {
    background-color: #fff5e5;
    border-radius: 16px;
    padding: 43px 60px
}

.activity-section .content-block .info-block .title {
    color: #321e14;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2
}

.activity-section .content-block .nav-tabs .nav-link {
    background-color: transparent;
    border-color: transparent;
    color: hsla(0,0%,9%,.7);
    font-size: 22px;
    font-weight: 500;
    margin-top: -.5px;
    padding: 4px 0 8px
}

.activity-section .content-block .nav-tabs .nav-link.active {
    border-bottom-color: #f0a77e;
    color: #f0a77e
}

.activity-section .content-block .tab-content .tab-body {
    padding-top: 14px;
    font-size: 14px;
}

.activity-section .content-block .tab-content .tab-body .lbl {
    color: #181818;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 6px
}

.activity-section .content-block .tab-content .tab-body .sub-lbl {
    color: #321e14;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px
}

.activity-section .content-block .tab-content .tab-body .text {
    color: #321e14;
    font-size: 18px
}

.activity-section .content-block .tab-content .tab-body .info-text {
    color: #321e14;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 6px
}

.activity-section .content-block .tab-content .tab-body .info-text span {
    color: #f68446
}

.activity-section .content-block .tab-content .tab-body .list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0
}

.activity-section .content-block .tab-content .tab-body .list .text {
    margin-bottom: 0
}

.activity-section .content-block .tab-content .tab-body .list li {
    padding-left: 20px;
    position: relative
}

.activity-section .content-block .tab-content .tab-body .list li:not(:last-child) {
    margin-bottom: 16px
}

.activity-section .content-block .tab-content .tab-body .list li:before {
    aspect-ratio: 1/1;
    background-color: #f68446;
    border-radius: 50%;
    content: "";
    left: 0;
    position: absolute;
    top: 7px;
    width: 12px
}

.activity-section .content-block .payment-block .banner .img-container {
    isolation: isolate;
    margin-bottom: 19px;
    position: relative;
    text-align: center
}

.activity-section .content-block .payment-block .banner .img-container img {
    border-radius: 16px;
    max-height: 240px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

.activity-section .content-block .payment-block .banner .img-container .discount-block {
    aspect-ratio: 1/1;
    background-color: #1baa1b;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 25px;
    font-weight: 600;
    left: 19px;
    place-content: center;
    place-items: center;
    position: absolute;
    top: 9px;
    width: 80px;
    z-index: 99
}

.activity-section .content-block .payment-block .booking-card {
    background-color: rgba(246,132,70,.12);
    border-radius: 16px
}

.activity-section .content-block .payment-block .booking-card .list-group-item {
    background-color: transparent;
    border-color: transparent transparent #b9b9b9;
    padding: 8px 18px
}

.activity-section .content-block .payment-block .booking-card .list-group-item:last-child {
    border-bottom: 0
}

.activity-section .content-block .payment-block .booking-card .info {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: space-between
}

.activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
    color: #321e14;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.33
}

.activity-section .content-block .payment-block .booking-card .info .price-block {
    display: inline-flex;
    gap: 14px
}

.activity-section .content-block .payment-block .booking-card .info .price.discount {
    text-decoration: line-through;
    text-decoration-color: #ff1414
}

.activity-section .content-block .payment-block .booking-card .info .input-block {
    max-width: 146px;
    width: 100%
}

.activity-section .content-block .payment-block .booking-card .info .form-control {
    border: 1px solid #707070;
    border-radius: 2px;
    font-size: 14px;
    opacity: .67;
    padding: 10px 9px
}

.activity-section .content-block .payment-block .booking-card .info .form-control::-moz-placeholder {
    color: rgba(0,0,0,.3)
}

.activity-section .content-block .payment-block .booking-card .info .form-control::placeholder {
    color: rgba(0,0,0,.3)
}

.activity-section .content-block .payment-block .booking-card .info .input-group .form-control {
    color: #000;
    font-size: 20px;
    padding: 6px;
    text-align: center
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn {
    border-color: hsla(0,0%,44%,.67)
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn:first-child {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px
}

.activity-section .content-block .payment-block .booking-card .info .input-group .btn:hover svg g, .activity-section .content-block .payment-block .booking-card .info .input-group .btn:hover svg rect {
    opacity: 1
}

.activity-section .content-block .payment-block .booking-card .btn-container {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 13px;
    padding-top: 13px
}

.activity-section .content-block .payment-block .booking-card .btn-container .btn {
    border-radius: 3px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 12px;
    padding-top: 12px;
    text-transform: uppercase;
    white-space: nowrap
}

.activity-section .content-block .payment-block .booking-card .btn-container .btn-som {
    background-color: #b41d6e
}

.activity-section .content-block .payment-block .booking-card .btn-container .btn-som:hover {
    background-color: #9a195e
}

.activity-section .content-block .payment-block .booking-card .form-check [type=checkbox] {
    border-color: #aeaeae;
    border-radius: 1px
}

.activity-section .content-block .payment-block .booking-card .form-check .form-check-label {
    color: rgba(0,0,0,.7);
    font-size: 14px
}

.activity-section .content-block .payment-block .booking-card .form-check .form-check-label a {
    color: rgba(1,41,201,.7)
}

.activity-section .content-block .payment-block .booking-card .payment-getway {
    padding-top: 17px
}

.activity-section .content-block .payment-block .booking-card .payment-getway .img-container {
    align-items: center;
    display: flex;
    gap: 8px
}

.activity-section .content-block .payment-block .booking-card .payment-getway .img-container img {
    max-width: 35px
}

#confetti {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%;
    z-index: -1
}

@media (max-width: 992px) {
    .activity-section .content-block {
        grid-template-columns: 1.2fr 1fr
    }

    .activity-section .content-block .info-block {
        padding: 35px 32px
    }

    .activity-section .content-block .info-block .title {
        font-size: 28px
    }

    .activity-section .content-block .nav-tabs .nav-link {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .lbl {
        font-size: 20px
    }

    .activity-section .content-block .tab-content .tab-body .sub-lbl {
        font-size: 17px
    }

    .activity-section .content-block .tab-content .tab-body .info-text, .activity-section .content-block .tab-content .tab-body .text {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .list li:before {
        top: 6px;
        width: 10px
    }

    .activity-section .content-block .payment-block .banner .img-container img {
        border-radius: 8px
    }

    .activity-section .content-block .payment-block .banner .img-container .discount-block {
        font-size: 20px;
        width: 70px
    }

    .activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
        font-size: 18px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-block {
        max-width: 140px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .form-control {
        font-size: 18px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .btn svg {
        width: 17px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container .btn {
        font-size: 16px
    }
}

@media (max-width: 767px) {
    .payment-block {
        width: 100% !important;
        max-width: unset !important;
    }

    .activity-section .content-block {
        gap: 40px;
        grid-template-columns: 1fr;
        padding-bottom: 70px;
        padding-top: 65px
    }

    .activity-section .content-block .info-block {
        padding: 28px 20px
    }

    .activity-section .content-block .info-block .title {
        font-size: 24px
    }

    .activity-section .content-block .nav-tabs .nav-link {
        font-size: 15px
    }

    .activity-section .content-block .tab-content .tab-body .lbl {
        font-size: 18px
    }

    .activity-section .content-block .tab-content .tab-body .sub-lbl {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .info-text, .activity-section .content-block .tab-content .tab-body .text {
        font-size: 14px
    }

    .activity-section .content-block .tab-content .tab-body .list li {
        padding-left: 16px
    }

    .activity-section .content-block .tab-content .tab-body .list li:before {
        width: 8px
    }

    .activity-section .content-block .payment-block .banner .img-container .discount-block {
        font-size: 16px;
        width: 60px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .form-control, .activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
        font-size: 16px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container .btn {
        font-size: 15px
    }
}

@media (max-width: 575px) {
    .activity-section .content-block {
        gap: 15px;
        grid-template-columns: 1fr;
        padding-bottom: 70px;
        padding-top: 20px
    }

    .activity-section .content-block .info-block {
        padding: 12px 10px
    }

    .activity-section .content-block .info-block .title {
        font-size: 16px;
        margin-bottom: 4px
    }

    .activity-section .content-block .nav-tabs .nav-link {
        font-size: 12px;
        padding-bottom: 6px
    }

    .activity-section .content-block .tab-content .tab-body {
        padding-top: 8px
    }

    .activity-section .content-block .tab-content .tab-body .lbl {
        font-size: 14px
    }

    .activity-section .content-block .tab-content .tab-body .sub-lbl {
        font-size: 16px
    }

    .activity-section .content-block .tab-content .tab-body .info-text, .activity-section .content-block .tab-content .tab-body .text {
        font-size: 12px;
        margin-bottom: 5px
    }

    .activity-section .content-block .tab-content .tab-body .list li {
        padding-left: 16px
    }

    .activity-section .content-block .tab-content .tab-body .list li:not(:last-child) {
        margin-bottom: 6px
    }

    .activity-section .content-block .tab-content .tab-body .list li:before {
        width: 8px
    }

    .activity-section .content-block .payment-block .banner .img-container {
        margin-bottom: 12px
    }

    .activity-section .content-block .payment-block .banner .img-container .discount-block {
        font-size: 14px;
        width: 50px
    }

    .activity-section .content-block .payment-block .banner .img-container img {
        max-height: 170px
    }

    .activity-section .content-block .payment-block .booking-card .info .lbl, .activity-section .content-block .payment-block .booking-card .info .text {
        font-size: 14px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .form-control {
        font-size: 12px;
        padding-bottom: 4px;
        padding-top: 4px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-group .btn {
        padding: 3px 6px
    }

    .activity-section .content-block .payment-block .booking-card .info .input-block {
        max-width: 125px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container {
        padding-bottom: 8px;
        padding-top: 8px
    }

    .activity-section .content-block .payment-block .booking-card .btn-container .btn {
        font-size: 14px;
        padding-bottom: 6px;
        padding-top: 6px
    }

    .activity-section .content-block .payment-block .booking-card .payment-getway {
        padding-top: 12px
    }
}

p {
    margin-bottom: 1rem;
    margin-top: 0;
}

.tab-body li:before {
    aspect-ratio: 1/1;
    background-color: #f68446;
    border-radius: 50%;
    content: "";
    left: 2px;
    position: absolute;
    width: 12px;
    top: 8px;
}

.tab-body li {
    padding-left: 20px;
    position: relative;
}

.tab-body p {
    margin-bottom: 0px !important;
}

.form-check label {
    display: inline-flex;
}

a:hover {
    color: #6a86af !important;
    cursor: pointer;
}

.payment-block {
    max-width: 443px
}

.payment-block .swiper-slide, .payment-block .swiper-wrapper {
    width: 100% !important;
    max-width: 443px
}

.new-info {
    background-color: transparent !important;
    font-size: 18px;
    text-align: justify;
}