@import '../../common-style//variable.scss';

.profile_edit_page_main {
    .info_main {

        .info_li {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .form_input {
            background-color: $White;
            border-radius: 8px;
            box-shadow: $Box-Shadow-Custom;
        }
       

        .action_otr {
            display: flex;
        }
    }
}

.circle2 {
    display: inline-block;
      background-color: orangered;
      border-radius: 50%;
  }
  
  .circle2-inner {
    color: white;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 138px;
    width: 138px;
    font-size: 65px;
    background-color: #fc9765;
    border-radius: 50%;
  
  }