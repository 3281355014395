.search_edit_main{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.search_edit_main table{
    font-size: 25px !important;
}
.edit_img{
    width: 34px;
    cursor: pointer;
    margin-top: -7px;
}

td:nth-child(2){
    text-align: center;
}
.couponsContent{
    display: flex;
    width: 100%;
    margin-top: 15px;
    @media (max-width:1678px){
        flex-direction: column;
    }
}
.action_otr{
    display: flex;
    gap: 10px;
}
.leftCouponsContent{
    width: 65%;
    min-width: 1057px;
    padding-left: 10px;
}
.rightCouponsContent{
    width: 35%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media (max-width: 1050px) {
        width: 100%;
    }
}
.topFloor{
    display: flex;
    justify-content: space-between;
}
.couponNumber{
    position: absolute;
    left: -32px;
    top: calc(50% - 13px);
    background: #449e48;
    width: 27px;
    height: 27px;
    font-size: 13px;
    display: flex;
    z-index: 99999;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: white;
}
.addCouponForm{
    width: 100%;
    max-width: 350px;
    background-color: #FEEAE0;
    border-radius: 12px;
    padding: 20px;
    height: min-content;
}
table, th, td {
    border: 1px solid lightgray;
    text-align: center;
    padding: 2px;
}