@import '../../common-style/variable.scss';


.footer_main {
    background: transparent linear-gradient(101deg,#fffae9,#ffd6d0 100%,#f25042 0) 0 0 no-repeat padding-box;
    color: black;

    .row_custom {
        padding: 31px 0 28px 0;
        gap: 48px 0;

        @media (max-width: 575px) {
            gap: 36px 0;
            padding: 72px 0;
        }
    }

    .payment_options {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        color: #321e14;
        margin-top: 20px;
    }

    .payment_options img {
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    .payment_options p {
        display: flex;
        align-items: center;
        color: rgba(50,30,20,.3);
        font-size: 10px;
        font-weight: 500;
        text-transform: capitalize;
    }

    .payment_options p img {
        margin-left: 5px;
        border-radius: 50%;
    }

    .logo_otr {
        display: flex;
    }

    .logo {
        width: 220px;
        height: 40px;
        object-fit: contain;
        object-position: left;
    }

    .desc {
        color: #321e14;
        ;
        margin-top: 24px;
    }

    .desc2 {
        color: #321e14;
        ;
        margin: 10px 0 24px;
    }

    .social_ul {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .social_a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        border-radius: 100%;
        background-color: rgb(246, 132, 70);
        backdrop-filter: blur(12.5px);
        transition: .3s;

        &:hover {
            background-color: rgb(246, 132, 70);

            .social_icon {
                color: whitesmoke;
            }
        }

        .social_icon {
            font-size: 24px;
            line-height: 24px;
            color: whitesmoke;
            transition: .3s;
        }
    }

    .col_link_inr {
        padding-left: 24px;

        @media (max-width: 992px) {
            padding-left: 0;
        }

        .heading {
            color: #fc9765;
            margin-bottom: 12px;

            @media (max-width: 575px) {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .link_li {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .link_a {
            position: relative;
            color: #321e14;
            transition: .3s;
            overflow: hidden;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 24px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                top: 50%;
                left: -12px;
                transform: translateY(-50%);
                background-color: #321e14;
                border-radius: 100%;
                transition: .3s;
            }

            &:hover {
                color: $Primary-Light2;
                padding-left: 22px;

                &::after {
                    left: 0;
                }
            }
        }
    }

    .col_sub_inr {
        padding-left: 30px;

        @media (max-width: 992px) {
            padding-left: 0;
        }

        .heading_sub {
            color: #fc9765;

            @media (max-width: 575px) {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .desc_sub {
            color: #321e14;
            margin: 10px 0 24px 0;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        .form_otr {
            margin: 0;
            padding: 0;
            display: inline-flex !important;
        }

        .Theme_btn_white {
            margin-top: 24px;
        }
    }

    .col_sub_inr2 {
        padding-left: 30px;

        .desc_sub {
            color: #321e14;
            margin: 10px 0 0 0;
        }

        @media (max-width: 992px) {
            padding-left: 0;
        }
    }

    .copy_right_main {
        border-top: 1px solid gray;


        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .cr_text {
            text-align: center;
            color: #321e14;
        }

        .cr_text_inr {
            display: inline;
            color: #321e14;
            transition: .3s;
            text-decoration: underline;

            &:hover {
                color: $Primary-Light2;
            }
        }
    }
}

.footer_main .Theme_input_light {
    background-color: white;
    color: black;
}

.footer_main .Theme_input_light::placeholder {
    background-color: white;
    color: darkgray;
}




footer {
    background: transparent linear-gradient(101deg,#fffae9,#ffd6d0 100%,#f25042 0) 0 0 no-repeat padding-box;
    padding-bottom: 45px;
    padding-top: 45px
}

footer .footer-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%
}

footer .footer-list .logo-container {
    margin-bottom: 29px;
    max-width: 213px;
    width: 100%
}

footer .footer-list .logo-container img {
    height: 100%;
    width: 100%
}

footer .footer-list .info-block {
    align-items: center;
    display: grid;
    gap: 9px;
    grid-template-columns: auto 1fr;
    margin-bottom: 19px
}

footer .footer-list .info-block .text-lbl {
    color: #321e14;
    font-size: 26px;
    font-weight: 500
}

footer .footer-list .payment-methods {
    margin-top: 25px
}

footer .footer-list .payment-methods .img-container {
    align-items: center;
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
    width: 100%
}

footer .footer-list .payment-methods .img-container img {
    height: 100% !important;
    max-width: 29px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    width: 100%
}

footer .footer-list .payment-methods .text {
    color: rgba(50,30,20,.3);
    font-size: 10px;
    font-weight: 500;
    text-transform: capitalize
}

footer .footer-list .text-title {
    font-size: 26px;
    font-weight: 500
}

footer .footer-list .social-links-container {
    text-align: center;
}



footer .footer-list .social-links-container .list-inline {
    margin: 0;
    padding: 0;
    display: inline-flex;
    gap: 9px;
}

footer .footer-list .social-links-container .list-inline .list-inline-items:not(:last-child) {
    margin-right: 22px
}

footer .footer-list .title {
    color: #321e14;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px
}

footer .footer-list .contact-container {
    display: flex
}

footer .footer-list .contact-container .form-control {
    border: 0;
    border-radius: 4px 0 0 4px;
    color: #321e14;
    font-size: 16px;
    max-width: 300px;
    padding: 12px 17px
}

footer .footer-list .contact-container .form-control::-moz-placeholder {
    color: rgba(50,30,20,.18)
}

footer .footer-list .contact-container .form-control::placeholder {
    color: rgba(50,30,20,.18)
}

footer .footer-list .contact-container .form-control:focus {
    box-shadow: none
}

footer .footer-list .contact-container .btn {
    border-radius: 0 4px 4px 0;
    color: #ffeeec;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 22px;
    white-space: nowrap ;
    background-color:#f68446;
    border:0px;
}




@media (max-width: 1400px) {
    footer .footer-list .title {
        font-size: 32px
    }

    footer .footer-list .info-block .text-lbl, footer .footer-list .text-title {
        font-size: 21px
    }
}



@media (max-width: 1200px) {
    .top-navbar {
        padding-bottom: 14px;
        padding-top: 14px
    }

    .top-navbar .navbar-nav .nav-item:not(:last-child) {
        margin-right: 14px
    }

    .top-navbar .navbar-nav .nav-item .nav-link {
        font-size: 16px;
        padding: 12px 14px;
        text-align: center
    }

    .top-navbar .btn {
        padding: 12px 10px
    }

    .top-navbar .btn svg rect {
        height: 3.5px
    }

    footer .footer-list .title {
        font-size: 28px
    }

    footer .footer-list .text-title {
        font-size: 22px
    }

    footer .footer-list .social-links-container .list-inline-item svg {
        height: auto;
        width: 38px
    }

    footer .footer-list .info-block .icon svg {
        height: auto;
        width: 26px
    }

    footer .footer-list .info-block .text-lbl {
        font-size: 22px
    }
}

@media (max-width: 991px) {
    .top-navbar .navbar-brand {
        max-width: 205px
    }

    footer .footer-container {
        display: grid;
        grid-template-columns: 1fr .6fr 1fr
    }

    footer .footer-list .title {
        font-size: 22px
    }

    footer .footer-list .contact-container .btn {
        padding-left: 16px;
        padding-right: 16px
    }

    footer .footer-list .info-block .text-lbl, footer .footer-list .text-title {
        font-size: 20px
    }
}

@media (max-width: 767px) {
    
    footer .footer-container {
        display: grid;
        gap: 35px;
        grid-template-columns: 1fr
    }

    footer .footer-list .logo-container {
        max-width: 180px
    }

    footer .footer-list .info-block {
        max-width: 245px
    }

    footer .footer-list .info-block .text-lbl {
        font-size: 18px
    }

    footer .footer-list .title {
        font-size: 20px;
        margin-bottom: 16px
    }

    footer .footer-list .contact-container .btn, footer .footer-list .contact-container .form-control {
        font-size: 14px
    }
}

@media (max-width: 575px) {
    
    footer {
        padding-bottom: 35px;
        padding-top: 35px
    }
    .social_a
    {

    }

    footer .footer-container {
        gap: 25px
    }

    footer .footer-list .logo-container {
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 110px
    }

    footer .footer-list .info-block {
        display: block;
        margin-bottom: 14px;
        margin-left: auto;
        margin-right: auto;
        max-width: 245px;
        text-align: center
    }

    footer .footer-list .info-block .icon {
        display: inline-block;
        margin-right: 4px
    }

    footer .footer-list .info-block .text-lbl {
        display: inline-block;
        font-size: 16px
    }

    footer .footer-list .title {
        text-align: center
    }

    footer .footer-list .text-title {
        margin-bottom: 8px;
        text-align: center
    }

    footer .footer-list .payment-methods .img-container {
        justify-content: center
    }

    footer .footer-list .payment-methods .text, footer .footer-list .social-links-container .list-inline {
        text-align: center
    }

    footer .footer-list .social-links-container .list-inline .list-inline-item:not(:last-child) {
        margin-right: 6px
    }

    footer .footer-list .social-links-container .list-inline .list-inline-item svg {
        height: 30px;
        width: 30px
    }
    .contact-container {
        text-align: center;
    }

    .form_otr {
        margin: 0 auto;
        display: inline-block; /* Ensure the form takes only as much width as necessary */
    }
}

.form_otr {
    display: inline-flex !important;
}