.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.slider-container {
    display: flex;
    gap: 10px;
}

.image-container {
    .uploadd_img {
        object-fit: contain;
        object-position: center;
    }
}

.delete-button {
    border: none;
    border-radius: 0 0 4px 4px;
    background-color: rgba($color: red, $alpha: 0.5);
    color: white;
    width: 100%;
}