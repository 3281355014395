@import "../../common-style//variable.scss";

.button-green {
  background-color: green !important;
}

.button-red {
  background-color: red !important;
}

.profile_page_main {
  .profile_img_content_main {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid $Black-10;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .img_content_otr {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .profile_img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      object-position: center;
    }

    .user_name {
      color: $Black;
    }

    .designation_text {
      color: $Black-65;
      font-family: $font-family-Medium;
      margin: 6px 0 12px 0;
    }

    .location_otr {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .location_icon {
      font-size: 24px;
      line-height: 24px;
      color: $Black-65;
    }

    .location_text {
      color: $Black-65;
      font-family: $font-family-Medium;
    }

    .edit_btn_otr {
      display: flex;
      align-items: center;
      gap: 12px;
      background-color: $Primary;
      color: $White;
      border-radius: 4px;
      padding: 8px 20px;
      border: none;
      outline: none;
      transition: 0.3s;

      &:hover {
        background-color: $Primary-Dark;
      }

      .btn_icon {
        font-size: 20px;
        line-height: 20px;
        color: $White;
      }

      .btn_text {
        color: $White;
      }
    }
  }

  .info_main {
    padding: 24px 0;
    border-bottom: 1px solid $Black-10;

    .info_heading {
      color: $Black;
      margin-bottom: 16px;
    }

    .form_main {
      margin: 0;
      padding: 0;
    }

    .info_li {
      display: flex;
      align-items: center;
      gap: 24px;

      .input-container {
        width: 100%;
        flex: 1;
      }
    }

    .input_label {
      width: 250px;
      color: $Black;
    }

    .form_input {
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .profile_table_main {
    .heading_filter_otr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 24px 0;

      .heading_activity {
        color: $Black;
      }

      .search_otr {
        position: relative;

        .search_icon {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 20px;
          line-height: 20px;
          color: $Black-65;
        }

        .search_input {
          padding-left: 46px;
          width: 250px;
        }
      }
    }

    .profile_table {
      max-height: 400px;
      overflow-y: auto;

      .data_table {
        width: 100%;
      }

      .table_header {
        background-color: $Grey-Bg;
        border-bottom: 1px solid $Black-25;
        position: sticky;
        top: 0;
        left: 0;

        tr {
          th {
            padding: 8px 0;

            &:first-child {
              width: 110px;
            }

            &:nth-child(3) {
              width: 170px;
            }

            &:nth-child(4) {
              width: 130px;
            }

            &:nth-child(5),
            &:nth-child(6) {
              width: 140px;
            }

            &:last-child {
              width: 100px;
            }

            &:not(:last-child) {
              .header_text {
                border-right: 1px solid $Black-25;
              }
            }
          }

          .header_text {
            color: $Black;
            padding: 0 10px 0 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }

      .table_body {
        tr {
          height: 72px;
          border-bottom: 1px solid $Black-25;
          padding: 8px 0;

          td {
            &:first-child {
              .body_text {
                color: $Black;
              }
            }

            &:not(:last-child) {
              .body_text {
                border-right: 1px solid $Black-25;
              }
            }
          }

          .body_text {
            color: $Black-65;
            padding: 5px 10px 5px 16px;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .prodile_otr {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 10px 0 16px;
            border-right: 1px solid $Black-25;

            .profile_img {
              width: 36px;
              height: 36px;
              object-fit: cover;
              object-position: center;
              border-radius: 100%;
            }

            .profile_name {
              color: $Black-65;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          .status_text_otr {
            display: flex;
            padding: 5px 10px 5px 16px;
            border-right: 1px solid $Black-25;

            .status_text {
              font-size: 12px;
              line-height: 19px;
              border-radius: 4px;
              padding: 4px 12px;
            }

            .paid_text {
              color: $White;
              background-color: $Primary;
              width: 140px;
              text-align: center;
              height: 50px;
              font-size: 16px;
              line-height: 24px;
              cursor: pointer;

              display: grid;
              align-items: center;
            }

            .unpaid_text {
              color: $Black-65;
              background-color: $Black-3;
            }
          }

          .role_select_otr {
            display: flex;
            padding: 5px 10px 5px 16px;
            border-right: 1px solid $Black-25;

            .form-select {
              padding: 0 24px 0 0;
              background-color: transparent;
              background-position: right 0.25rem center;
            }
          }

          .icon_otr {
            display: flex;
            align-items: center;
            padding: 5px 10px 5px 16px;

            .edit_icon {
              font-size: 20px;
              line-height: 20px;
              color: $Black-65;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.circle2 {
  display: inline-block;
  background-color: orangered;
  border-radius: 50%;
}

.circle2-inner {
  color: white;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  height: 138px;
  width: 138px;
  font-size: 65px;
  background-color: #fc9765;
  border-radius: 50%;

}