@import '../../common-style//variable.scss';

.analytics_card_inr {
    background-color: $White;
    box-shadow: $Box-Shadow-Custom;
    border-radius: 8px;
    padding: 16px;

    .icon_otr {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: $Primary-Light2;

        .card_icon {
            font-size: 24px;
            line-height: 23px;
            color: $Primary;
        }
    }

    .Card_heading {
        color: $Black;
        line-height: $line-height-30px;
        margin: 12px 0 8px 0;
    }

    .name_percent_otr {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name_text {
            color: $Black-65;
            font-family: $font-family-Medium;
            text-transform: uppercase;
        }

        .green_text {
            color: $Green;
        }

        .red_text {
            color: $Red;
        }

        .black_text {
            color: $Black-65;
        }
    }
}