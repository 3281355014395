
 
.main
{
    display: inline-block;
}
.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 554px;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .activitiesContainer {
    padding: 30px 60px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    background-color: wheat;
    
  }