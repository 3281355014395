@import '../../common-style/variable.scss';

.partner_page {
    .partner_contact {
        padding: 112px 0 128px 0;

        @media (max-width: 575px) {
            padding: 36px 0 48px 0;
        }

        .desc {
            &:not(:last-child) {
                margin: 0 0 16px 0;
            }

            &:last-child {
                margin: 0;
            }
        }
    }

    .partner_main {
        padding: 112px 0 128px 0;

        @media (max-width: 575px) {
            padding: 48px 0 72px 0;
        }

        .content_wrapper {
            margin-bottom: 72px;

            @media (max-width: 768px) {
                margin-bottom: 36px;
            }
        }

        .swiper_main {
            background-color: $Primary-Light2;
            padding: 56px 72px 48px 72px;
            border-radius: 16px;

            @media (max-width: 768px) {
                padding: 48px 36px 36px 36px;
            }

            @media (max-width: 575px) {
                padding: 36px 20px 20px 20px;
            }

            .logo_swiper {
                padding-bottom: 58px;
            }

            .swiper-pagination {
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: 0;
                gap: 12px;
            }

            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: $Black-25;
                margin: 0;
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                background-color: $Primary;
                opacity: 1;
            }

            .swiper-wrapper {
                height: auto;
            }

            .logo_otr {
                display: flex;
                align-items: center;
                justify-content: center;

                .logo_img {
                    height: 52px;
                    width: auto;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }
}

.partner-wrapper {
    padding-right: 48px;
}

.partner-wrapper p {
    text-align: justify !important;
}

.partner-wrapper li {
    font-size: 22px;
    list-style: circle;
    margin-left: 23px;
}

.partner-wrapper li p {
    margin-bottom: 8px;
}

.partner-wrapper ul {
    margin: 14px 14px;
}