@import '../../common-style/variable.scss';

.inner_header_main {
    color: #f68446;

    @media (max-width: 575px) {
        padding-top: 96px;
    }

    .header_wrapper {
        padding: 28px 0 27px 0;
        text-align: center;
        font-size: 40px;
        text-transform: UPPERCASE;
        font-weight: 700;
        letter-spacing: 1.5px;

        @media (max-width: 575px) {
            padding: 0 0 36px 0;
            font-size: 20px !important;
        }
    }

    .header_heading {

        @media (max-width: 420px) {
            font-size: 32px;
            line-height: 48px;
        }
    }

    .header_desc_link {
        color: $White;

        span {
            padding-right: 10px;
        }
    }

    .linkk_home {
        position: relative;
        display: inline-flex;
        overflow: hidden;
        color: $White;
        padding-left: 10px;
        transition: .3s;

        &:hover {
            &::after {
                left: 10px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            left: -100%;
            bottom: 0;
            background-color: $White;
            transition: .3s;
        }
    }
}