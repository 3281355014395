@import '../../common-style/variable.scss';

.text_heading {
    font-size: 22px;

    @media (max-width: 575px) {
        font-size: 16px;
    }
}

.order_activity {
    .campaign_img {
        margin: 0 !important;
    }
}

.campaign_Page {


    .campaign_main {
        padding: 112px 0 128px 0;

        @media (max-width: 575px) {
            padding: 36px 0 48px 0;
        }
    }

    .row_custom {
        gap: 48px 0;

        @media (max-width: 575px) {
            flex-direction: column-reverse;
            gap: 36px 0;
        }
    }

    .col_content_inr {
        padding-right: 48px;

        @media (max-width: 992px) {
            padding-right: 0;
        }

        .campaign_text {
            color: $Black-65;

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        .heading_terms {
            color: $Black;
            margin: 16px 0 12px 0;
        }

        .action_otr {
            display: inline-flex;
            margin-top: 40px;

            @media (max-width: 575px) {
                margin-top: 24px;
            }
        }
    }

    .col_img_inr {
        .campaign_swiper {
            background-color: $White;
            box-shadow: $Box-Shadow-Custom;
            padding: 24px;
            border-radius: 16px;

            @media (max-width: 575px) {
                padding: 16px;
            }
        }

        .campaign_img {
            width: 100%;
            height: 400px;
            object-fit: cover;
            object-position: center;
            border-radius: 10px;
            // animation-name: UpDown;
            // animation-duration: 6s;
            // animation-iteration-count: infinite;
            // animation-timing-function: linear;

            @media (max-width: 575px) {
                height: 320px;
            }
        }

        .social_text_otr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $Primary-Light2;
            border-radius: 8px;
            padding: 24px 36px;
            margin-top: 36px;

            @media (max-width: 575px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 16px 0;
                margin-top: 24px;
                padding: 24px 20px;
            }
        }

        .text_ul {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 16px;

            @media (max-width: 575px) {
                gap: 8px;
            }
        }

        .text_heading {
            display: flex;
            align-items: center;
            color: $Black;

            span {
                font-family: $font-family-Medium;
                color: $Black-65;
                margin-left: 4px;
            }

            .icon_text_otr {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-right: 16px;
            }

            .q_icon {
                font-size: 24px;
                line-height: 24px;
                color: $Primary;
                transition: .3s;
            }
        }

        .social_ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            // width: 120px;
            gap: 16px;
        }

        .social_a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $White;
            box-shadow: $Box-Shadow-Custom;
            transition: .3s;

            &:hover {
                background-color: $Primary;

                .social_icon {
                    color: $White;
                }
            }

            .social_icon {
                font-size: 24px;
                line-height: 24px;
                color: $Primary;
                transition: .3s;
            }
        }
    }
}