@import '../../common-style/variable.scss';

.accordion-item {
    border: none;
    border-radius: 0 !important;
    border-bottom: 1px solid $Black-25;

    .accordion-button {
        display: flex;
        align-items: center;
        gap: 20px;
        border-radius: 0 !important;
        border: none;
        background-color: transparent;
        box-shadow: none;
        padding: 12px;

        @media (max-width: 575px) {
            padding: 0 0 16px 0;
            gap: 16px;
        }

        &::after {
            display: none;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .accordion_heading {
        color: $Black;
        padding-top: 18px;
        line-height: 18px !important;
        font-size:22px !important;

        @media (max-width: 575px) {
            font-size: $font-size-16px;
            line-height: $line-height-28px;
        }
    }

    .heading_Icon {
        font-size: 30px;
        line-height: 30px;
        color: $Primary;
        transform: rotate(315deg);
        transition: .3s;

        @media (max-width: 575px) {
            font-size: 24px;
            line-height: 24px;
        }
    }

    .collapsed {
        .heading_Icon {
            transform: rotate(0);
        }
    }

    .accordion-collapse {
        border-radius: 0 !important;
    }

    .accordion-body {
        padding: 0 0 24px 48px;

        @media (max-width: 575px) {
            padding: 0 0 16px 0;
        }
    }

    .desc_otr {
        padding: 12px 24px 16px 24px;
        border-left: 4px solid $Primary;

        @media (max-width: 575px) {
            padding: 8px 0px 12px 16px;
        }
    }

    .accordion_desc {
        font-size: 22px;
        line-height: $line-height-28px;
        font-family: $font-family-Medium;
        color: $Black-65;
        text-align: justify;
    }
}