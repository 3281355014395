@import '../../common-style/variable.scss';

.col_teamCard_inr {
    .card_img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
    }

    .Card_Heading {
        color: $Black;
        margin: 16px 0 8px 0;
        text-align: center;
        transition: .3s;

        &:hover {
            color: $Primary;
        }
    }

    .Card_Desc {
        color: $Black-65;
        text-align: center;
    }
}