.action-card {
    border: 1px solid #ccc;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    background-color: #eca68329;

    h4 {
        margin: 0 0 10px 0;
    }

    button {
        margin-top: 10px;
        background-color: #fc9765;
        color: white;
        padding: 5px 10px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        margin-right: 5px;
        transition: all 0.3s;

        &:focus {
            outline: none;
        }
    }

    input {
        margin: 5px 0;
        padding: 5px;
        width: 100%;

        &[type="color"] {
            height: 52px;
        }
    }

    .Delete {
        background: #f44336;
        margin: 0px;
        float: right;
        margin-left: 43px;
    }

    .inline {
        display: inline-flex;
        width: 100%;

        h4,
        span {
            width: 50%;
        }
    }
}
