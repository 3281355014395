@import '../../common-style/variable.scss';

.Team_building_main {
    padding: 112px 0 128px 0;

    .row_custom {
        justify-content: center;
    }

    .col_team_building_inr {
        background-color: $Primary-Light2;
        padding: 56px 72px 72px 72px;
        border-radius: 16px;

        .heading {
            color: $Black;
            text-align: center;
        }

        .search_otr {
            position: relative;
            margin: 20px 0 24px 0;
        }

        .search_input {
            padding-right: 52px;
            box-shadow: none;

            &::-webkit-search-cancel-button {
                display: none;
            }
        }

        .search_icon_otr {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 52px;
            height: 52px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 0 8px 8px 0;
            background-color: $Primary;
            cursor: pointer;
        }

        .search_icon {
            font-size: 24px;
            color: $White;
        }

        .activity_main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $White;
            border-radius: 8px;
            padding: 6px 24px;
            margin: 24px 0;
        }

        .activity_ul {
            display: flex;
            align-items: center;
            gap: 16px;

            .Theme_select {
                font-size: $font-size-14px;
                font-family: $font-family-SemiBold;
                background-color: transparent;
                box-shadow: none;
                border-right: 1px solid $Black-25;
                border-radius: 0;
                color: $Black-65;
                padding: 6px 40px 6px 0;
                cursor: pointer;
            }
        }

        .filter_otr {
            .Theme_select {
                background-color: transparent;
                font-size: $font-size-16px;
                font-family: $font-family-SemiBold;
                box-shadow: none;
                border-left: 1px solid $Black-25;
                border-radius: 0;
                color: $Black-65;
                padding: 6px 40px 6px 16px;
                background-position: right 0.25rem center;
                background-image: url(../../img/filter-icon.svg);
                background-size: 24px 24px;
                cursor: pointer;
            }
        }

        .relevent_select_otr {
            display: flex;
            align-items: center;
            justify-content: center;

            .Theme_select {
                font-size: $font-size-16px;
                font-family: $font-family-SemiBold;
                width: auto;
                box-shadow: none;
                padding: 12px 40px 12px 24px;
                background-color: $White;
                color: $Black-65;
                background-position: right 0.85rem center;
                cursor: pointer;
            }
        }
    }

    .col_inpiration_inr {
        margin-top: 48px;

        .heading {
            color: $Black;
            margin-bottom: 36px;
            text-align: center;
        }

        .inspiration_ul {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 24px;
        }

        .inspiration_btn {
            font-size: $font-size-16px;
            line-height: $line-height-24px;
            font-family: $font-family-Bold;
            color: $Primary;
            background-color: transparent;
            border: 1px solid $Primary;
            border-radius: 8px;
            padding: 12px 16px;
            transition: .3s;
            cursor: pointer;
            outline: none;

            &:hover {
                color: $White;
                background-color: $Primary;
            }
        }
    }

    .col_logo_inr {
        .heading {
            color: $Black;
            margin-bottom: 48px;
            text-align: center;
        }

        .logo_ul {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 72px;
            flex-wrap: wrap;
        }

        .logo_img {
            height: 36px;
            width: auto;
            object-fit: contain;
        }
    }
}