@import '../../common-style/variable.scss';

.form-disabled > select
{
   cursor: not-allowed !important;
   color: rgba(0, 0, 0, 0.5) !important;
    
}
.Theme_select {
    font-size: $font-size-14px;
    line-height: $line-height-26px;
    font-family: $font-family-Medium;
    padding: 8px 36px 8px 16px;
    color: $Black-65;
    background-color: $Black-3;
    border-radius: 8px;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}