@import '../../common-style/variable.scss';

.search-button {
    border: none;
    background: none;
}

.padding {
    margin-top: 50px;
}

.searchRsult_page {
    .searchRsult_main {
        padding: 53px 0 84px 0;

        @media (max-width: 575px) {
            padding: 36px 0 48px 0;
        }
    }

    .filter_heading {
        color: $Black;
    }

    .filter_main {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 20px 0;
    }

    .activity_main {
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 450px) {
            align-items: flex-start;
            gap: 0;
        }

        .search_icon_otr_local {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            border-radius: 8px;
            background-color: $Black;
            cursor: pointer;
            transition: .3s;

            @media (max-width: 450px) {
                height: 40px;
            }

            &:hover {
                background-color: $Primary-Dark;
            }

            @media (max-width: 1440px) {
                border-radius: 4px;
            }
        }

        .search_icon_local {
            font-size: 16px;
            color: $White;
            background-color: transparent;
            border: none;
            outline: none;
            width: 100%;
        }
    }

    .activity_ul {
        display: flex;
        align-items: center;
        gap: 6px;
        background-color: $Black-3;
        padding: 6px 10px 6px 16px;
        border-radius: 8px;

        @media (max-width: 450px) {
            display: grid;
            gap: 0;
            width: 100%;
            grid-template-columns: auto auto auto auto;
            padding: 6px 0 12px 6px;
            border-radius: 8px 8px 0 0;
        }

        .activity_li {
            &:not(:first-child) {
                .Theme_select {
                    padding-left: 10px;
                }
            }

            &:nth-child(2),
            &:nth-child(4) {
                @media (max-width: 450px) {
                    border-right: none;
                }

                .Theme_select {
                    @media (max-width: 450px) {
                        border-right: none;
                    }
                }
            }

            @media (max-width: 450px) {
                border-bottom: 1px solid $Black-65;

                .Theme_select {
                    padding-left: 10px;
                }
            }
        }

        .Theme_select {
            padding: 2px 36px 2px 0;
            background-color: transparent;
            border-radius: 0;
            background-position: right 0.65rem center;
        }
    }

    .row_custom {
        gap: 24px 0;
    }

    .action_otr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 72px;
    }
}