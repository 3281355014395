@import "../../common-style/variable.scss";

.about_page {
  .about_row_custom2 {
    flex-direction: row-reverse;
    margin-top: 128px;

    .col_content_inr {
      padding-right: 0;
      padding-left: 48px;
    }
  }
}

.terms-section {
  padding: 20px 10px;
}

.ul-style {
  margin-left: 50px;
  margin-top: 20px;
}
.ul-style > li {
  list-style: circle;
}
.heading-h2 {
  font-size: 36px;
}
.sub-heading {
  font-size: 28px;
  margin: 0px 10px;
  color: rgb(66, 65, 65);
  font-weight: 400 !important;
}

.sub-text
{
  margin-left: 10px;
}
.heading-s {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 17px;
  text-align: left;
}
.heading-s > a
{
    display: inline;
}
.term-wrapper p
{
    text-align:justify !important;

}

.term-wrapper a {
    display: unset;
}
.term-wrapper br {
    margin-bottom:12px !important;
}
