@import '../../common-style/variable.scss';

.dashboard_header {
    position: relative;
    padding: 20px 24px;
    z-index: 998;

    @media (max-width: 992px) {
        padding: 16px;
    }

    @media (max-width: 575px) {
        padding: 12px 16px;
    }

    .wrapper {
        display: flex;
        align-items: center;
        gap: 24px;

        @media (max-width: 575px) {
            gap: 16px;
        }
    }

    .sidebar_menu_icon_otr {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: $Black-3;
        border-radius: 4px;
        cursor: pointer;
        transition: .3s;

        @media (max-width: 575px) {
            width: 32px;
            height: 32px;
        }

        &:hover {
            background-color: $Primary;

            .sidebar_menu_icon {
                color: $White;
            }
        }
    }

    .sidebar_menu_icon {
        font-size: 20px;
        line-height: 20px;
        color: $Black-65;
        transition: .3s;

        @media (max-width: 575px) {
            font-size: 16px;
            line-height: 16px;
        }
    }

    .search_otr {
        position: relative;
        border-left: 1px solid $Black-10;
        padding-left: 24px;
        flex: 1;

        @media (max-width: 575px) {
            padding-left: 16px;
        }
    }

    .search_icon {
        position: absolute;
        font-size: 20px;
        line-height: 20px;
        color: $Black-40;
        top: 50%;
        left: 24px;
        transform: translateY(-50%);

        @media (max-width: 575px) {
            font-size: 16px;
            line-height: 16px;
            left: 16px;
        }
    }

    .search_input {
        padding: 8px 16px 8px 36px;
        background-color: transparent;
        box-shadow: none;

        @media (max-width: 575px) {
            padding: 4px 16px 4px 28px;
        }
    }

    .profile_main {
        position: relative;
        border-left: 1px solid $Black-10;
        padding-left: 24px;

        @media (max-width: 575px) {
            padding-left: 16px;
        }

        &:hover {
            .profile_drop_otr {
                opacity: 1;
                visibility: visible;
                top: 40px;
            }
        }

        .profile_otr {
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
        }

        .profile_img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            object-position: center;
            border-radius: 100%;

            @media (max-width: 575px) {
                width: 32px;
                height: 32px;
            }
        }

        .user_name {
            color: $Black-40;

            @media (max-width: 575px) {
                display: none;
            }
        }

        .profile_drop_otr {
            position: absolute;
            top: 100px;
            right: 0;
            padding-top: 20px;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
        }

        .profile_drop_ul {
            width: 200px;
            background-color: $White;
            box-shadow: $Box-Shadow-Custom;
            border-radius: 4px;
            padding: 16px 20px 20px 20px;
        }

        .user_text {
            color: $Black-65;
        }

        .profile_drop_link {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px 0;
            border-bottom: 1px solid $Black-10;
            transition: .3s;

            &:hover {
                border-bottom: 1px solid $Primary;

                .menu_icon {
                    color: $Primary;
                }

                .menu_text {
                    color: $Primary;
                }
            }
        }

        .menu_icon {
            font-size: 16px;
            line-height: 16px;
            color: $Black-65;
            transition: .3s;
        }

        .menu_text {
            font-family: $font-family-Medium;
            color: $Black-65;
            transition: .3s;
        }
    }
}