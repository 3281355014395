@import "../../common-style//variable.scss";

.custom
{
    margin-top: 33px !important;
}
.city
{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.dateRange {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}
.radio-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0;
}
.radio {
  display: flex;
  gap: 5px;
}

.partner_analytics_page {
  .heading_otr {
    margin-bottom: 24px;
  }

  .heading_partner {
    color: $Black;
  }

  .statistics_main {
    .row_custom {
      gap: 24px 0;
    }
  }
}
